import axios from "axios";
import { logout } from "../Redux/Actions/AuthActions";
import { store } from "../Redux/store";
import { getToken } from "../Services/authentication";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token  = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };
    if ([401].includes(error?.response?.status)) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      store.dispatch(logout());
    }

    if (error?.response?.status === 403) {
      notificationParam.message = "Not Authorized";
    }

    if (error?.response?.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error?.response?.status === 508) {
      notificationParam.message = "Time Out";
    }

    // notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default instance;
