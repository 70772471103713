import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { getToken, logout } from "../Services/authentication";

const PublicRoute = () => {
  const { loading } = useSelector((state) => state?.auth);

  const getCurrentUserInfo = () => {
    let userInfo = null;
    try {
      userInfo = JSON.parse(localStorage.getItem("currentUser") || null);
    } catch (error) {
      logout();
      window.location.reload();
    }
    return userInfo;
  };

  const token = getToken();
  const currentUser = getCurrentUserInfo();

  if (loading) {
    return <Spin />;
  }

  return token && currentUser ? (
    currentUser.role === "admin" ? (
      <Navigate to={"/dashboard"} />
    ) : (
      <Navigate to={"/history"} />
    )
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
