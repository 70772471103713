import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllTags = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_TAGS_INIT,
    });
    instance
      .get(`/tags`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_TAGS_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_TAGS_FAIL,
            payload: res.data?.message || "Failed to get tags",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_TAGS_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get tags",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const createNewTag = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NEW_TAG_INIT,
    });
    instance
      .post(`/tags`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.CREATE_NEW_TAG_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.CREATE_NEW_TAG_FAIL,
            payload: res.data?.message || "Failed to create new tag",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.CREATE_NEW_TAG_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed create new tag",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getTagById = (tagId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TAG_BY_ID_INIT,
    });
    instance
      .get(`/tags/${tagId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_TAG_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_TAG_BY_ID_FAIL,
            payload: res.data?.message || "Failed to get tag",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_TAG_BY_ID_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to get tag",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const editTag = (tagId, data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_TAG_INIT,
    });
    instance
      .put(`/tags/${tagId}`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.EDIT_TAG_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.EDIT_TAG_FAIL,
            payload: res.data?.message || "Failed to update tag",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.EDIT_TAG_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed update tag",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const deleteTag = (tagId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TAG_INIT,
    });
    instance
      .delete(`/tags/${tagId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.DELETE_TAG_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.DELETE_TAG_FAIL,
            payload: res.data?.message || "Failed to delete new tag",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.DELETE_TAG_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed delete new tag",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
