import { Button, Col, Row, Space, Tabs, DatePicker } from "antd";
import React from "react";
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import "./Dashboard.scss";

const { RangePicker } = DatePicker;

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const barData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function Dashboard() {
  const TabExtra = (
    <Space className="tab-extra">
      <Button type="text">All day</Button>
      <Button type="text">All week</Button>
      <Button type="text">All month</Button>
      <Button type="text active">All year</Button>
      <RangePicker />
    </Space>
  );

  return (
    <div className="dashboard-main-wrap">
      <Row gutter={24}>
        <Col span={6}>
          <div className="square-widget-wrap">
            <span className="title">Active users</span>
            <span className="count">2,781</span>
            <ResponsiveContainer aspect={2.3} width={"110%"}>
              <AreaChart
                width={"110%"}
                height={100}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#9760e4"
                  fill="#9760e4"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col span={6}>
          <div className="square-widget-wrap">
            <span className="title">Prompts</span>
            <span className="count">3,241</span>
            <ResponsiveContainer aspect={2.3} width={"110%"}>
              <AreaChart
                width={"110%"}
                height={100}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="pv"
                  stroke="#9760e4"
                  fill="#9760e4"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col span={6}>
          <div className="square-widget-wrap">
            <span className="title">Questions</span>
            <span className="count">253</span>
            <ResponsiveContainer aspect={2.3} width={"110%"}>
              <AreaChart
                width={"110%"}
                height={100}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="amt"
                  stroke="#9760e4"
                  fill="#9760e4"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col span={6}>
          <div className="square-widget-wrap">
            <span className="title">Organizations</span>
            <span className="count">4,324</span>
            <ResponsiveContainer aspect={2.3} width={"110%"}>
              <AreaChart
                width={"110%"}
                height={100}
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#9760e4"
                  fill="#9760e4"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
      <Tabs className="bottom-tabs" tabBarExtraContent={TabExtra}>
        <Tabs.TabPane tab="Sales" key="item-1">
          <ResponsiveContainer aspect={5} height="100px">
            <BarChart width={100} height={40} data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="uv" fill="#391085" opacity={0.6} />
            </BarChart>
          </ResponsiveContainer>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Visits" key="item-2">
          <ResponsiveContainer aspect={5} height="100px">
            <BarChart width={100} height={40} data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="pv" fill="#391085" opacity={0.6} />
            </BarChart>
          </ResponsiveContainer>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Dashboard;
