import React from "react";
import PropTypes from "prop-types";
import { Button, Result } from "antd";

function EmailVerificationStatus({
  status,
  title,
  subTitle,
  onClick,
  btnText,
}) {
  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <Button type="primary" key="console" onClick={onClick}>
          {btnText}
        </Button>
      }
    />
  );
}

EmailVerificationStatus.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  btnText: PropTypes.string.isRequired,
};

export default EmailVerificationStatus;
