import { Breadcrumb, Row, Col, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkUnwrapImages from "remark-unwrap-images";
import numberedFootnoteLabels from "remark-numbered-footnote-labels";
import "./HistoryDetails.scss";
import { CopyOutlined } from "@ant-design/icons";

function HistoryDetails() {
  const params = useParams();
  const location = useLocation();
  const [messages, setMessages] = useState([
    { message: "Welcome! what would you like to chat about?", gpt: true },
  ]);
  useEffect(() => {
    if (location?.state?.id) {
      setMessages([
        { message: "Welcome! what would you like to chat about?", gpt: true },
        ...location?.state?.chats?.map((item) => ({
          message: item?.message,
          prompt_name: item?.prompt_name,
          gpt: item?.is_bot,
          user: !item?.is_bot,
        })),
      ]);
    }
  }, [location?.state?.id]);
  return (
    <div className="history-details-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/history">{`History`}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{params?.history_name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="history-details-wrap">
        <Row>
          <Col span={24} className="content-col">
            <div className="header-section">
              <span className="title">{params?.history_name}</span>
            </div>
            <Divider />
            <div className="content-section">
              <div className="chat-section">
                {messages?.map((message) => {
                  if (message?.user) {
                    return (
                      <div className="right-chat-box">
                        <ReactMarkdown
                          remarkPlugins={[
                            remarkGfm,
                            remarkBreaks,
                            remarkUnwrapImages,
                            numberedFootnoteLabels,
                          ]}
                        >
                          {message?.prompt_name || message?.message}
                        </ReactMarkdown>
                      </div>
                    );
                  } else {
                    return (
                      <div className="left-chat-box">
                        <ReactMarkdown
                          remarkPlugins={[
                            remarkGfm,
                            remarkBreaks,
                            remarkUnwrapImages,
                            numberedFootnoteLabels,
                          ]}
                        >
                          {message?.message}
                        </ReactMarkdown>
                        <CopyOutlined
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigator.clipboard.writeText(message?.message)
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HistoryDetails;
