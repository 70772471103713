import React, { useEffect, useState } from "react";
import { notification, Result, Spin } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router";

import { getCurrentUserDetails } from "../Services/user";
import InActiveSubscriptionMessage from "../Component/InActiveSubscriptionMessage";
import PageLoader from "../Component/PageLoader";
import { getSubscriptionDetails } from "../Services/subscription";
import ReactivateSubscription from "../Component/ReactivateSubscription";

export const paymentStatuses = ["in_trial", "active", "non_renewing"];

const SubscriptionVerifiedRoute = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionInfoLoader, setSubscriptionInfoLoader] = useState(false);

  const [newSubscriptionLoader, setNewSubscriptionLoader] = useState(false);
  const [showTryAgainMessage, setShowTryAgainMessage] = useState(false);

  const {
    REACT_APP_CHARGEBEE_NEW_SUBSCRIPTION_URL,
    REACT_APP_CHARGEBEE_RENEW_SUBSCRIPTION_URL,
  } = process.env;

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const chargeBeeHostedPageId = urlSearchParams.get("id");
  const chargeBeeHostedPageState = urlSearchParams.get("state");

  const getUserInfo = async () => {
    try {
      const response = await getCurrentUserDetails(true);
      if (response?.user) {
        setUserInfo(response.user);
      } else {
        notification.error({
          message: response.data?.message,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserInfo();
  }, []);

  const getSubscriptionInfo = async (subscriptionId) => {
    try {
      const response = await getSubscriptionDetails(subscriptionId);
      if (response?.data?.success) {
        if (response.data?.subscriptionDetails) {
          setSubscriptionData(response.data?.subscriptionDetails);
        }
        setSubscriptionInfoLoader(false);
      } else {
        notification.error({
          message: response.data?.message,
        });
        setSubscriptionInfoLoader(false);
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || error.message,
      });
      setSubscriptionInfoLoader(false);
    }
  };

  useEffect(() => {
    if (userInfo?.payments?.[0]?.subscription_id) {
      setSubscriptionInfoLoader(true);
      getSubscriptionInfo(userInfo.payments[0].subscription_id);
    }
  }, [userInfo]);

  useEffect(() => {
    let interval = null;

    if (chargeBeeHostedPageId && chargeBeeHostedPageState === "succeeded") {
      if (userInfo) {
        if (!userInfo.payments?.[0]?.subscription_id) {
          setNewSubscriptionLoader(true);
          interval = setInterval(async () => {
            const response = await getCurrentUserDetails(true);
            if (response?.user?.payments?.[0]?.subscription_id) {
              window.location.reload();
            }
          }, 5000);

          setTimeout(() => {
            setNewSubscriptionLoader(false);
            setShowTryAgainMessage(true);
            if (interval) {
              clearInterval(interval);
            }
          }, 60000);
        } else {
          setNewSubscriptionLoader(false);
        }
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [userInfo, chargeBeeHostedPageId, chargeBeeHostedPageState]);

  if (newSubscriptionLoader) {
    return (
      <Result
        icon={<Spin />}
        title="Verifying"
        subTitle={
          <>
            <p>Please wait!</p>
            <p>We are currently verifying the payment status.</p>
          </>
        }
      />
    );
  }

  if (
    !loading &&
    !subscriptionInfoLoader &&
    !newSubscriptionLoader &&
    showTryAgainMessage
  ) {
    return (
      <Result
        title="Try again"
        subTitle={
          <>
            <p>We did not receive a response for the payment.</p>
            <p>Please check again after a few minutes.</p>
          </>
        }
      />
    );
  }

  if (loading || subscriptionInfoLoader) {
    return <PageLoader />;
  }

  if (userInfo?.role === "admin") {
    return <Outlet />;
  }

  if (!loading) {
    if (!userInfo?.payments || !userInfo?.payments?.length > 0) {
      return (
        <InActiveSubscriptionMessage
          link={`${REACT_APP_CHARGEBEE_NEW_SUBSCRIPTION_URL}&customer[email]=${encodeURIComponent(
            userInfo?.email
          )}`}
        />
      );
    }

    if (
      !paymentStatuses.includes(subscriptionData?.status?.toLowerCase()) &&
      subscriptionData?.status !== "cancelled"
    ) {
      return (
        <InActiveSubscriptionMessage
          link={`${REACT_APP_CHARGEBEE_RENEW_SUBSCRIPTION_URL}&customer[email]=${encodeURIComponent(
            userInfo?.email
          )}`}
        />
      );
    }
    if (subscriptionData?.status === "cancelled") {
      return (
        <ReactivateSubscription
          subscriptionId={userInfo.payments[0].subscription_id}
        />
      );
    }
  }
  return <Outlet />;
};

export default SubscriptionVerifiedRoute;
