import { Breadcrumb, Button, Row, Col, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkUnwrapImages from "remark-unwrap-images";
import numberedFootnoteLabels from "remark-numbered-footnote-labels";
import {
  getSuccessPlanById,
  updateSuccessPlanById,
} from "../../Redux/Actions/CompanyActions";
import "./SuccessPlanDetails.scss";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

function SuccessPlanDetails() {
  const params = useParams();
  const navigation = useNavigate();
  const [exportSuccessLoading, setExportSuccessLoading] = useState(false);
  const dispatch = useDispatch();
  const { loading, successPlanDetails } = useSelector(
    (state) => state?.company
  );
  const [successPlanData, setSuccessPlanData] = useState({});

  useEffect(() => {
    dispatch(getSuccessPlanById(params?.successPlanId));
  }, [params?.successPlanId]);

  useEffect(() => {
    if(!successPlanDetails){
      return
    }
    if(successPlanDetails?.is_draft){
      navigation(`/success-plans/${successPlanDetails?.id}/draft`)
    }else{
      navigation(`/success-plans/${successPlanDetails?.id}`)
    }
    setSuccessPlanData({
      company_overview: {
        label: "Company Overview",
        value: successPlanDetails?.company_overview,
        editMode: false,
      },
      business_objective: {
        label: "Business Objectives",
        value: successPlanDetails?.business_objective,
        editMode: false,
      },
      operational_problems: {
        label: "Operational Problems",
        value: successPlanDetails?.operational_problems,
        editMode: false,
      },
      business_problems: {
        label: "Business Problems",
        value: successPlanDetails?.business_problems,
        editMode: false,
      },
      success_criteria: {
        label: "Success Criteria",
        value: successPlanDetails?.success_criteria,
        editMode: true,
      },
      key_milestones: {
        label: "Key Milestones",
        value: successPlanDetails?.key_milestones,
        editMode: true,
      },
      measure_of_success: {
        label: "Measure of Success",
        value: successPlanDetails?.measure_of_success,
        editMode: true,
      },
    });
  }, [successPlanDetails]);

  const handleEditClick = (plankey) => {
    const currentPlanData = { ...successPlanData };
    currentPlanData[plankey].editMode = true;
    setSuccessPlanData(currentPlanData);
  };

  const handleSaveClick = (plankey) => {
    const currentPlanData = { ...successPlanData };
    currentPlanData[plankey].editMode = false;
    setSuccessPlanData(currentPlanData);
  };

  const handleExportSuccessPlan = () => {
    const successPlanDataPayload = {
      company_overview: successPlanData?.company_overview?.value,
      business_objective: successPlanData?.business_objective?.value,
      operational_problems: successPlanData?.operational_problems?.value,
      business_problems: successPlanData?.business_problems?.value,
      success_criteria: successPlanData?.success_criteria?.value,
      key_milestones: successPlanData?.key_milestones?.value,
      measure_of_success: successPlanData?.measure_of_success?.value,
    };
    setExportSuccessLoading(true);
    dispatch(
      updateSuccessPlanById(
        params?.successPlanId,
        successPlanDataPayload,
        () => {
          setExportSuccessLoading(false);
          message.success("Updated successfully");
        },
        (error) => {
          setExportSuccessLoading(false);
          message.error(error || "Fail to update");
        }
      )
    );
  };

  return (
    <div className="success-plan-details-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/success-plans">{`Success Plans`}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{params?.successPlanId}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className="success-plan-details-wrap">
          <div className="success-plan-details-top-section">
            <div className="top-section-left-wrap">
              <div className="left-header">
                <span>{`${successPlanDetails?.name}`}</span>
                <a
                  target="_blank"
                  href={`https://${successPlanDetails?.website}`}
                >{`${successPlanDetails?.website}`}</a>
              </div>
            </div>
            <div className="top-section-right-wrap">
              <Space>
                <Button
                  loading={exportSuccessLoading}
                  type="primary"
                  onClick={handleExportSuccessPlan}
                >
                  {successPlanDetails?.is_draft ?  'Export Success Plan' : 'Save' }
                </Button>
              </Space>
            </div>
          </div>
          <div className="success-plan-details-content">
            <Row gutter={[24, 24]}>
              {Object.keys(successPlanData || {})?.map((planKey) => {
                return (
                  <Col span={12}>
                    <div className="company-overview-box">
                      <div className="overview-top">
                        <span>{successPlanData[planKey]?.label}</span>
                        {!successPlanData[planKey]?.editMode ? (
                          <EditOutlined
                            onClick={() => handleEditClick(planKey)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <SaveOutlined
                            onClick={() => handleSaveClick(planKey)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <div className="overview-content">
                        {!successPlanData[planKey]?.editMode ? (
                          <ReactMarkdown
                            remarkPlugins={[
                              remarkGfm,
                              remarkBreaks,
                              remarkUnwrapImages,
                              numberedFootnoteLabels,
                            ]}
                          >
                            {successPlanData[planKey]?.value}
                          </ReactMarkdown>
                        ) : (
                          <TextArea
                            onChange={(e) => {
                              const currentPlanData = { ...successPlanData };
                              currentPlanData[planKey].value = e.target.value;
                              setSuccessPlanData(currentPlanData);
                            }}
                            value={successPlanData[planKey]?.value}
                            rows={5}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default SuccessPlanDetails;
