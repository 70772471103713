import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllPrompts = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_PROMPTS_INIT,
    });
    instance
      .get(`/prompts`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_PROMPTS_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_PROMPTS_FAIL,
            payload: res.data?.message || "Failed to get prompts",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_PROMPTS_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get prompts",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const createNewPrompt = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NEW_PROMPT_INIT,
    });
    instance
      .post(`/prompts`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.CREATE_NEW_PROMPT_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.CREATE_NEW_PROMPT_FAIL,
            payload: res.data?.message || "Failed to create new prompt",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.CREATE_NEW_PROMPT_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed create new prompt",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getPromptById = (promptId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_PROMPT_BY_ID_INIT,
    });
    instance
      .get(`/prompts/${promptId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_PROMPT_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_PROMPT_BY_ID_FAIL,
            payload: res.data?.message || "Failed to get prompt",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_PROMPT_BY_ID_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to get prompt",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const editPrompt = (promptId, data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_PROMPT_INIT,
    });
    instance
      .put(`/prompts/${promptId}`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.EDIT_PROMPT_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.EDIT_PROMPT_FAIL,
            payload: res.data?.message || "Failed to update prompt",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.EDIT_PROMPT_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed update prompt",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const deletePrompt = (promptId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_PROMPT_INIT,
    });
    instance
      .delete(`/prompts/${promptId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.DELETE_PROMPT_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.DELETE_PROMPT_FAIL,
            payload: res.data?.message || "Failed to delete new prompt",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.DELETE_PROMPT_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed delete new prompt",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
