import React, { useEffect, useState } from "react";
import { Spin, notification } from "antd";

import { getCurrentUserDetails } from "../Services/user";
import EmailVerificationStatus from "../Component/EmailVerificationStatus";
import { logout, requestEmailVerificationLink } from "../Services/authentication";

const EmailVerifiedRoute = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const response = await getCurrentUserDetails(true);
      if (response?.user) {
        setUserInfo(response.user);
      } else {
        notification.error({
          message: response.data?.message,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const response = await requestEmailVerificationLink(userInfo.id);
      if (response.data.success) {
        notification.success({
          message: "We have sent an email verification link to your email.",
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
        await logout();
        window.location.reload();
      } else {
        notification.error({
          message: response.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (loading) {
    return <Spin />;
  }

  const subTitle = ({email}) => {
    const style = {margin: "0"}
    return (
      <>
        <p style={style} >A verification email has been sent to <b>{email}</b>.</p>
        <p style={style}>If you have already verified your email account, please refresh the page.</p>
        <p style={style}>If you have not received a verification email, please request a new one by using the <b>Resend Verification</b> button below.</p>
      </>
    );
  }
  
  if (!loading && !userInfo?.is_email_verified) {
    return (
      <EmailVerificationStatus
        status="info"
        title="Verify Email"
        subTitle={subTitle({email: userInfo?.email})}
        btnText="Resend Verification"
        onClick={resendVerificationEmail}
      />
    );
  }

  return children;
};

export default EmailVerifiedRoute;
