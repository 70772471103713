import React from "react";
import { Button, Result } from "antd";
import PropTypes from "prop-types";

function InActiveSubscriptionMessage({ link }) {
  const subTitle = () => {
    return (
      <>
        <p>It appears that you don't have an active subscription plan.</p>
        <p>
          In case you have made the payment and are still seeing this screen,
          please contact the support team at{" "}
          <a href="mailto:support@salesbod.ai">support@salesbod.ai</a>.
        </p>
      </>
    );
  };
  return (
    <Result
      title="Oops!"
      subTitle={subTitle()}
      extra={
        <Button type="primary" key="console" onClick={() => window.open(link)}>
          Get Subscription
        </Button>
      }
    />
  );
}

InActiveSubscriptionMessage.propTypes = {
  link: PropTypes.string.isRequired,
};

export default InActiveSubscriptionMessage;
