import { Button, Card, Modal, Result, notification } from "antd";
import React, { useEffect, useState } from "react";
import { getRefundDetails, initiateRefund } from "../../Services/subscription";
import { useSearchParams } from "react-router-dom";
import PageLoader from "../../Component/PageLoader";

function RefundDetails() {
    const [queryParams] = useSearchParams();
    const [confirmRefundVisible, setConfirmRefundVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [refundData, setRefundData] = useState(null)
    const [loading, setLoading] = useState(false)

    const token = queryParams.get("token")
    
    useEffect(() => {
        if(token)
        {
            getRefundInfo(token)
        }
    }, [token])

    const getRefundInfo = async (token) => {
        try {
          setLoading(true);
          const response = await getRefundDetails({token});
          if (response.data.success) {
            setRefundData(response.data.message)
            setLoading(false);
          } else {
            notification.error({
              message: response.data?.message,
            });
            setLoading(false);
          }
        } catch (error) {
          notification.error({
            message: error.response?.data?.message || error.message,
          });
          setLoading(false);
        }
    }
    
    const handleConfirmRefund = async (invoiceId,refundAmount) => {
        try {
            setConfirmLoading(true);
            const response = await initiateRefund({refundAmount:refundAmount}, invoiceId);
            if (response.data.success) {
                notification.success({message: response.data.message || "Refund initiated successfully"})
                setConfirmLoading(false);
                setConfirmRefundVisible(false);
            } else {
              notification.error({
                message: response.data?.message,
              });
              setConfirmLoading(false);
              setConfirmRefundVisible(false);
            }
          } catch (error) {
            notification.error({
              message: error.response?.data?.message || error.message,
            });
            setConfirmLoading(false);
            setConfirmRefundVisible(false);
          }  
    };
    
    if (loading) {
        return <PageLoader />;
    }

    if(!token){
        return (
            <Result
              status="403"
              title="403"
              subTitle="Invalid URL"
            />
          );
    }

    return (  
        <div>
            <Card title="Refund Details" bordered style={{ width: 300 }}>
                <p>Username: {refundData?.user?.firstName}</p>
                <p>Email: {refundData?.user?.email}</p>
                <p>Refund Amout: ${(refundData?.paymentDetails?.amount_paid)/100}</p>
                <p>Invoice ID: {refundData?.paymentDetails?.id}</p>
                <Button type="primary" onClick={() => setConfirmRefundVisible(true)}>
                    Confirm Refund
                </Button>
            </Card>
            {confirmRefundVisible && 
                <Modal
                    title="Confirm Refund"
                    open={confirmRefundVisible}
                    onCancel={() => setConfirmRefundVisible(false)}
                    confirmLoading={confirmLoading}
                    footer={[
                    <Button key="cancel" onClick={() => setConfirmRefundVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="confirm" type="primary" onClick={() => handleConfirmRefund(refundData?.paymentDetails?.id,refundData?.paymentDetails?.amount_paid)} loading={confirmLoading}>
                        Confirm
                    </Button>,
                    ]}
                >
                    <p>Are you sure you want to proceed with the refund?</p>
                </Modal>
            } 
        </div>
    )
}

export default RefundDetails;