import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllUsers = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_USERS_INIT,
    });
    instance
      .get(`/users`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_USERS_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_USERS_FAIL,
            payload: res.data?.message || "Failed to get users",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_USERS_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get users",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const createNewUser = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NEW_USER_INIT,
    });
    instance
      .post(`/users`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.CREATE_NEW_USER_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.CREATE_NEW_USER_FAIL,
            payload: res.data?.message || "Failed to create new user",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.CREATE_NEW_USER_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed create new user",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const editUser = (userId, data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_INIT,
    });
    instance
      .put(`/users/${userId}`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.UPDATE_USER_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.UPDATE_USER_FAIL,
            payload: res.data?.message || "Failed to update user",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.UPDATE_USER_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to update user",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const deleteUser = (userId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_USER_INIT,
    });
    instance
      .delete(`/users/${userId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.DELETE_USER_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.DELETE_USER_FAIL,
            payload: res.data?.message || "Failed to delete new user",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.DELETE_USER_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed delete new user",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
