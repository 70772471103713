import {
  Breadcrumb,
  Row,
  Col,
  Space,
  Button,
  Table,
  notification,
  Popconfirm,
  Modal,
  Form,
  Input,
} from "antd";
import Search from "antd/lib/input/Search";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Tags.scss";
import { useDispatch } from "react-redux";
import {
  deleteTag,
  getAllTags,
  editTag as updateTag,
  createNewTag,
} from "../../Redux/Actions/TagActions";
import { useSelector } from "react-redux";

const { Item } = Form;

function Tags() {
  const dispatch = useDispatch();
  const { tags, loading, paginationData } = useSelector((state) => state?.tag);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalVisible, setModalVisible] = useState(false);
  const [editTag, setEditTag] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllTags({ page: page, limit: pageSize }));
  }, []);

  const handleSearch = () => {
    dispatch(
      getAllTags({
        search: searchValue,
        page: 1,
        limit: pageSize,
      })
    );
    setPage(1);
  };

  const onPaginationChange = (page, pageSize) => {
    dispatch(
      getAllTags({
        search: searchValue,
        page,
        limit: pageSize,
      })
    );
    setPage(page);
    setPageSize(pageSize);
  };

  const handleReset = () => {
    dispatch(
      getAllTags({
        page: 1,
        limit: pageSize,
      })
    );
    setPage(1);
    setSearchValue();
  };

  const onDeleteTag = (tagId) => {
    dispatch(
      deleteTag(
        tagId,
        () => {
          notification.success({ message: "Tag deleted successfully!!" });
          handleReset();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to delete tag",
          });
        }
      )
    );
  };

  const handleCreateTag = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    form.resetFields();
    setEditTag();
  };

  const handleModalOk = async () => {
    form.validateFields().then((values) => {
      if (editTag) {
        dispatch(
          updateTag(
            editTag?.id,
            {
              name: values?.name,
            },
            () => {
              notification.success({ message: "Tag updated successfully!!" });
              setModalVisible(false);
              form.resetFields();
              setEditTag();
              handleReset();
            },
            (errorMessage) => {
              notification.error({
                message: errorMessage || "Failed to update tag",
              });
            }
          )
        );
      } else {
        dispatch(
          createNewTag(
            values,
            () => {
              notification.success({
                message: "Tag created successfully!!",
              });
              setModalVisible(false);
              form.resetFields();
              handleReset();
            },
            (errorMessage) => {
              notification.error({
                message: errorMessage || "Failed to create tag",
              });
            }
          )
        );
      }
    });
  };

  const columns = [
    {
      title: "Tag Name",
      dataIndex: "name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text) => moment(text)?.format("YYYY-MM-DD hh:mm:ss"),
    },
    {
      title: "Action",
      render: (_, data) => (
        <Space size={"small"}>
          <Button
            className={"action_btn"}
            type="link"
            onClick={() => handleEditTag(data)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this tag?"
            onConfirm={() => onDeleteTag(data?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={"action_btn"} type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  const handleEditTag = (tag) => {
    setEditTag(tag);
    form.setFieldsValue(tag);
    setModalVisible(true);
  };

  return (
    <div className="tags-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>{`Tags`}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="tags-table">
        <h4>Tags</h4>
        <Row gutter={24} justify="space-between">
          <Col span={20}>
            <Row gutter={30}>
              <Col span={6}>
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target?.value)}
                  placeholder="Search"
                />
              </Col>
              <Col span={4}>
                <Space>
                  <Button type="primary" onClick={handleSearch}>
                    Search
                  </Button>
                  <Button onClick={handleReset}>Reset</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col offset={2} span={2}>
            <Button onClick={handleCreateTag}>Create</Button>
          </Col>
        </Row>
        <Table
          rowKey={"id"}
          className="data-table"
          columns={columns}
          dataSource={tags}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: paginationData?.total,
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              onPaginationChange(page, pageSize);
            },
          }}
        />
      </div>
      <Modal
        title={editTag ? "Edit Tag" : "Create Tag"}
        visible={modalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
      >
        <Form form={form} layout="vertical">
          <Item
            name="name"
            label="Tag Name"
            rules={[{ required: true, message: "Please enter the tag name" }]}
          >
            <Input placeholder="Enter the tag name" />
          </Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Tags;
