import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllCompanies = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_COMPANIES_INIT,
    });
    instance
      .get(`/company`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_COMPANIES_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_COMPANIES_FAIL,
            payload: res.data?.message || "Failed to get companies",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_COMPANIES_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get companies",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getCompanyById = (companyId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_COMPANY_BY_ID_INIT,
    });
    instance
      .get(`/company/${companyId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_COMPANY_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_COMPANY_BY_ID_FAIL,
            payload: res.data?.message || "Failed to get company",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_COMPANY_BY_ID_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to get company",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const handleSummarizeCompanyById = (companyId, onSuccess, onFail) => {
  return async (disptach) => {
    instance
      .get(`/company/summarize/${companyId}`)
      .then((res) => {
        if (res.data?.success) {
          onSuccess && onSuccess(res.data);
        } else {
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getAllSuccessPlans = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_SUCCESS_PLANS_INIT,
    });
    instance
      .get(`/company/success-plans`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_SUCCESS_PLANS_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_SUCCESS_PLANS_FAIL,
            payload: res.data?.message || "Failed to get success plans",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_SUCCESS_PLANS_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed get success plans",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getSuccessPlanById = (successPlanId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_SUCCESS_PLAN_BY_ID_INIT,
    });
    instance
      .get(`/company/success-plan/${successPlanId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_SUCCESS_PLAN_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_SUCCESS_PLAN_BY_ID_FAIL,
            payload: res.data?.message || "Failed to get success plan",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_SUCCESS_PLAN_BY_ID_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed to get success plan",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const updateSuccessPlanById = (
  successPlanId,
  successPlanData,
  onSuccess,
  onFail
) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_INIT,
    });
    instance
      .put(`/company/success-plan/${successPlanId}`, successPlanData)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_FAIL,
            payload: res.data?.message || "Failed to update success plan",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed to update success plan",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
