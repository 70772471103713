import {
  Breadcrumb,
  Button,
  Empty,
  notification,
  Radio,
  Select,
  Space,
  Spin,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkUnwrapImages from "remark-unwrap-images";
import numberedFootnoteLabels from "remark-numbered-footnote-labels";
import {
  getAllCompanies,
  getCompanyById,
  handleSummarizeCompanyById,
} from "../../Redux/Actions/CompanyActions";
import "./CompanyDetails.scss";
import { CopyOutlined } from "@ant-design/icons";

function CompanyDetails() {
  const params = useParams();
  const navigation = useNavigate();
  const [summarizeLoading, setSummarizeLoading] = useState(false);
  const dispatch = useDispatch();
  const { companies, loading, companyDetails } = useSelector(
    (state) => state?.company
  );
  const [activeKey, setActiveKey] = useState("");
  const [selectedAiTool, setSelectedAiTool] = useState("chat_gpt");

  useEffect(() => {
    dispatch(getAllCompanies({}));
  }, []);

  useEffect(() => {
    dispatch(getCompanyById(params?.companyId));
  }, [params?.companyId]);

  useEffect(() => {
    if (!!companyDetails) {
      setActiveKey(companyDetails?.executed_prompts?.[0]?.id);
    }
  }, [companyDetails]);

  const handleCopy = () => {
    const promptData = [...companyDetails?.executed_prompts]?.find(
      (item) => item?.id === activeKey
    );
    navigator.clipboard.writeText(
      selectedAiTool === "chat_gpt"
        ? promptData?.chat_gpt_response
        : promptData?.bard_response
    );
  };

  const handleSummarizeCompany = () => {
    setSummarizeLoading(true);
    dispatch(
      handleSummarizeCompanyById(
        params?.companyId,
        (data) => {
          setSummarizeLoading(false);
          navigation(`/success-plans/${data?.success_plan?.id}/draft`);
        },
        (errorMessage) => {
          setSummarizeLoading(false);
          notification.error({
            message: errorMessage,
          });
        }
      )
    );
  };

  return (
    <div className="company-details-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/companies">{`Companies`}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{params?.companyId}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className="company-details-wrap">
          <div className="company-detail-top-section">
            <div className="top-section-left-wrap">
              <div className="left-header">
                <span>{`${companyDetails?.name}`}</span>
                <a
                  target="_blank"
                  href={`https://${companyDetails?.website}`}
                >{`${companyDetails?.website}`}</a>
              </div>
            </div>
            <div className="top-section-right-wrap">
              <Space>
                <Button
                  loading={summarizeLoading}
                  type="primary"
                  onClick={handleSummarizeCompany}
                >
                  Summarize All
                </Button>
                {/* <Button type="primary" onClick={handleCopy}>Copy</Button> */}
                {/* <Button type="primary">Export to Success Plan</Button> */}
              </Space>
            </div>
          </div>
          <div className="tabs-wrap">
            <Tabs
              type="card"
              onChange={(key) => setActiveKey(key)}
              activeKey={activeKey}
              items={companyDetails?.executed_prompts?.map((item) => ({
                key: item?.id,
                label: item?.name,
                children: (
                  <PovSummeryTabContent
                    promptData={item}
                    setAiTool={setSelectedAiTool}
                  />
                ),
              }))}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}

const PovSummeryTabContent = ({ promptData, setAiTool }) => {
  const [selectedAiTool, setSelectedAiTool] = useState("chat_gpt");
  return (
    <div className="pov-summary-tab-content">
      <div className="summary-content-wrap">
        <div className="ai-content">
          <div className="top-section-content">
            <label>Chat GPT</label>
            {!!promptData?.chat_gpt_response && (
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(promptData?.chat_gpt_response);
                }}
                className="copy-btn"
              />
            )}
          </div>
          <div className="content-inner">
            {promptData?.chat_gpt_response ? (
              <ReactMarkdown
                remarkPlugins={[
                  remarkGfm,
                  remarkBreaks,
                  remarkUnwrapImages,
                  numberedFootnoteLabels,
                ]}
              >
                {promptData?.chat_gpt_response}
              </ReactMarkdown>
            ) : (
              <Empty />
            )}
          </div>
        </div>
        <div className="ai-content">
          <div className="top-section-content">
            <label>BARD</label>
            {!!promptData?.bard_response && (
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(promptData?.bard_response);
                }}
                className="copy-btn"
              />
            )}
          </div>
          <div className="content-inner">
            {promptData?.bard_response ? (
              <ReactMarkdown
                remarkPlugins={[
                  remarkGfm,
                  remarkBreaks,
                  remarkUnwrapImages,
                  numberedFootnoteLabels,
                ]}
              >
                {promptData?.bard_response}
              </ReactMarkdown>
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
