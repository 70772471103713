import { UploadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Spin,
  notification,
  Switch,
  InputNumber,
  Upload,
  Radio,
  Checkbox,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createNewPrompt } from "../../Redux/Actions/PromptActions";
import "./PromptCreate.scss";

const { TextArea } = Input;

function PromptCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state?.prompt);

  const handleFormSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("prompt", values?.prompt);
    formData.append("icon", values?.icon?.file);
    formData.append("is_active", values?.is_active);
    formData.append("is_default", values?.is_default);
    !!values?.order && formData.append("order", values?.order);
    formData.append("ai_tool", values?.ai_tool?.join(","));
    dispatch(
      createNewPrompt(
        formData,
        () => {
          notification.success({ message: "Prompt created successfully!!" });
          navigate("/prompts");
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to create prompt",
          });
        }
      )
    );
  };

  const isDefault = Form.useWatch("is_default", form);

  return (
    <div className="prompt-create-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/prompts">{`Prompts`}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{"Create"}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className="prompt-create-wrap">
          <Row>
            <Col span={20} className="content-col">
              <div className="header-section">
                <span className="title">{"Create new prompt"}</span>
              </div>
              <Divider />
              <div className="content-section">
                <Form
                  form={form}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  onFinish={handleFormSubmit}
                  layout="horizontal"
                  autoComplete="off"
                >
                  <Row align={"middle"} justify={"center"}>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter the prompt name",
                          },
                        ]}
                        name={"name"}
                        label="Prompt Name"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter the prompt value",
                          },
                        ]}
                        name={"prompt"}
                        label="Prompt"
                      >
                        <TextArea rows={5}  />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Icon is required",
                          },
                        ]}
                        label="Icon"
                        name="icon"
                      >
                        <Upload
                          listType="picture"
                          action={null}
                          multiple={false}
                          beforeUpload={() => false}
                          accept={"image/*"}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Ai tool is required",
                          },
                        ]}
                        label="AI tool"
                        name="ai_tool"
                      >
                        <Checkbox.Group
                          options={[
                            { label: "Chat GPT", value: "chat_gpt" },
                            { label: "Bard", value: "bard" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        valuePropName="checked"
                        name={"is_active"}
                        label="Active"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        valuePropName="checked"
                        name={"is_default"}
                        label="Default"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    {isDefault && (
                      <Col span={24}>
                        <Form.Item name={"order"} label="Order">
                          <InputNumber />
                        </Form.Item>
                      </Col>
                    )}
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                      >
                        Create Prompt
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}

export default PromptCreate;
