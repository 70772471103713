import React from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./ForgotPassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../Redux/Actions/AuthActions";
import { useNavigate } from "react-router";

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const onFinish = (values) => {
    dispatch(
      forgotPassword(
        values,
        () => {
          notification.success({
            message: "Password reset link send to email",
          });
          navigate("/auth/login");
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failded to send password reset link",
          });
        }
      )
    );
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-wrapper">
        <h2>Forgot Password</h2>
        <Form size="large" name="login" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Send Reset Password Email
            </Button>
          </Form.Item>
        </Form>
        Back to <a href="/auth/login">Login</a>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
