import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import MainLayout from "../Component/MainLayout/MainLayout";
import EmailVerifiedRoute from "./EmailVerifiedRoute";
import SubscriptionVerifiedRoute from "./SubscriptionVerifiedRoute";
import { getToken } from "../Services/authentication";

const ProtectedRoute = () => {
  const token = getToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to={`/auth/login?redirect_url=${location.pathname}`} replace />;
  }

  return (
    <MainLayout>
      <EmailVerifiedRoute>
        <SubscriptionVerifiedRoute />
      </EmailVerifiedRoute>
    </MainLayout>
  );
};

export default ProtectedRoute;
