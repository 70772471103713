import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  user: null,
  loading: false,
  token: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action?.payload?.user,
        token: action.payload?.token,
      };
    case actionTypes.LOGIN_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SIGNUP_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SIGNUP_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FORGOT_PASSWORD_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_PASSWORD_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user:
          action?.payload?.user?.id === state?.user?.id
            ? action?.payload?.user
            : state?.user,
      };
    case actionTypes.LOGOUT:
      return initState;
    default:
      return state;
  }
};

export default store;
