import { Button, Modal, notification } from "antd";
import React, { useState, useEffect } from "react";
import "./SubscriptionDetails.scss";
import {getSubscriptionDetails, cancelSubscription, removeScheduledCancellation} from "../../Services/subscription";
import { getCurrentUserDetails } from "../../Services/user";
import { LoadingOutlined } from "@ant-design/icons";

function SubscriptionDetails({
  modalVisibility,
  handleOpenChange,
}) {
  const [loading, setLoading] = useState(false);
  const [estimateModal, setEstimateModal] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null)

  const toTitleCase = (status)=>(status ? status.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase()) : " ")
  
  const getSubscriptionInfo = async () => {
    try {
      setLoading(true);
      const userData = await getCurrentUserDetails(true);
      const response = await getSubscriptionDetails(userData.user.payments?.[0].subscription_id);
      if (response?.data?.subscriptionDetails) {
        setLoading(false);
        setSubscriptionDetails(response.data?.subscriptionDetails)
      } else {
        setLoading(false);
        notification.error({
          message: response.data?.message,
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  }

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const handleCancel = async () => {
    try {
      setLoading(true);
      const response = await cancelSubscription(subscriptionDetails.id);
      if(response.data.success){
        setLoading(false);
        notification.success({ message: response.data.message || "Subscription Cancelled Successfully" });
        await getSubscriptionInfo();
      }else {
        notification.error({
          message: response.data?.message,
        });
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || error.message,
      });
      setLoading(false);
    }
  };

  const onRemoveScheduledCancellation = async () => {
    try {
      setLoading(true);
      const response = await removeScheduledCancellation(subscriptionDetails.id);
      if (response.data.success) {
        notification.success({message: response.data.message || "Subscription reactivated successfully"})
        setLoading(false);
        setEstimateModal(false)
        await getSubscriptionInfo();
      } else {
        notification.error({
          message: response.data?.message,
        });
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || error.message,
      });
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        title={`Subscription Details | ${toTitleCase(subscriptionDetails?.status)}`}
        visible={modalVisibility}
        onCancel={handleOpenChange}
        footer={null}
        centered
      >
        <div className="content-container">
        {loading ? (
          <LoadingOutlined />
          ) : (
              <>
                <div className="info-list">
                  <p>
                    <span>Status :</span> <b>{toTitleCase(subscriptionDetails?.status)}</b>
                  </p>
                  <p>
                    <span>Amount :</span> <b>${subscriptionDetails?.unit_price}</b>
                  </p>
                {
                  subscriptionDetails?.started_at && <p>
                    <span>Subscription Date :</span> <b>{(new Date(subscriptionDetails?.started_at * 1000)).toLocaleDateString()}</b>
                  </p>
                }
                  {
                    subscriptionDetails?.cancelled_at && (<p>
                      <span>Expires At :</span> <b>{(new Date(subscriptionDetails?.cancelled_at * 1000)).toLocaleDateString()}</b>
                    </p>)
                  } 
                  <p>
                    <span>Plan :</span> <b>{subscriptionDetails?.item_price_id.replaceAll("-", " ")}</b>
                  </p>
                  <p>
                    <span>Subscription ID :</span> <b>{subscriptionDetails?.id}</b>
                  </p>
                </div>
                {(subscriptionDetails?.status === "active" || subscriptionDetails?.status === "in_trial") && !subscriptionDetails?.cancelled_at ? (
                  loading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      <Button type="primary" onClick={() => setConfirmModalVisible(true)}>
                        Cancel
                      </Button>
                      
                    </>
                  )
                ) : loading ? (
                  <LoadingOutlined />
                ) : (
                  <Button type="primary" onClick={()=> setEstimateModal(true)}>
                    Restart
                  </Button>
                )}
              </>
            )
          }
          </div>
      </Modal>
      {estimateModal && <Modal
        title="Confirm"
        visible={estimateModal}
        onCancel={() => setEstimateModal(false)}
        footer={null}
        centered
      >
        <div className="confirm-info">
          <p>Are you sure, you want to reactivate this subscription?</p>
          <div>
            <Button type="danger" onClick={() => setEstimateModal(false)} className="btn">
              Cancel
            </Button>
            <Button type="primary" onClick={onRemoveScheduledCancellation} className="btn" loading={loading}>
              Proceed
            </Button>
          </div>
        </div>
      </Modal>}
      {
        confirmModalVisible && <CustomConfirmationModal
          visible={confirmModalVisible}
          onConfirm={() => {
            handleCancel();
            setConfirmModalVisible(false);
          }}
          onCancel={() => setConfirmModalVisible(false)}
          loading={loading}
        />
      }
    </>
  );
}

export const CustomConfirmationModal = ({ visible, onConfirm, onCancel, loading }) => {
  return (
    <Modal title="Confirm" visible={visible} onCancel={onCancel} footer={null} centered>
      <div className="confirm-info">
        <p>Are you sure, you want to cancel this subscription?</p>
        <div>
          <Button type="danger" onClick={onCancel} className="btn">
            Cancel
          </Button>
          <Button type="primary" onClick={onConfirm} className="btn" loading={loading}>
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionDetails;
