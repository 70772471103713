import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Result, notification } from "antd";
import { useSearchParams, useNavigate } from "react-router-dom";

import EmailVerificationStatus from "../../Component/EmailVerificationStatus";
import {
  verifyEmail,
  requestEmailVerificationLink,
} from "../../Services/authentication";
import { getToken } from "../../Services/authentication";

function AlreadyVerified({ errorMessage }) {
  const navigate = useNavigate();
  const navigateToLogin = () => navigate("/auth/login?redirect_url=/");

  const subTitle = () => {
    const paragraphStyle = { margin: 0 };

    return (
      <>
        <p style={paragraphStyle}>{errorMessage}</p>
        <p style={paragraphStyle}>Redirecting to home...</p>
        <p style={paragraphStyle}>
          If not redirected in 2 seconds, use the login button.
        </p>
      </>
    );
  };

  useEffect(() => {
    if (getToken()) {
      setTimeout(() => {
        navigate("/");
      }, 200);
    }
  }, []);

  return (
    <EmailVerificationStatus
      status="info"
      title="Already Verified"
      subTitle={subTitle()}
      btnText="Log In"
      onClick={navigateToLogin}
    />
  );
}

function VerificationSuccess() {
  const navigate = useNavigate();
  const navigateToLogin = () => navigate("/auth/login?redirect_url=/");

  const subTitle = () => {
    const paragraphStyle = { margin: 0 };

    return (
      <>
        <p style={paragraphStyle}>Redirecting to home...</p>
        <p style={paragraphStyle}>
          If not redirected in 2 seconds, use the login button.
        </p>
      </>
    );
  };

  useEffect(() => {
    if (getToken()) {
      setTimeout(() => {
        navigate("/");
      }, 200);
    }
  }, []);

  return (
    <EmailVerificationStatus
      status="success"
      title="Email Verified"
      subTitle={subTitle()}
      btnText="Log In"
      onClick={navigateToLogin}
    />
  );
}

function VerifyEmail() {
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const verificationCode = searchParams.get("code");
  const userId = searchParams.get("userId");

  const verifyEmailAddress = async () => {
    setLoading(true);
    try {
      const response = await verifyEmail(verificationCode);
      if (response.data.success) {
        setIsVerified(true);
        setLoading(false);
      } else {
        setErrorMessage(response.data?.message);
        setLoading(false);
      }
    } catch (error) {
      if (error.response?.data?.errorCode) {
        setErrorCode(error.response.data.errorCode);
      }
      setErrorMessage(error.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const response = await requestEmailVerificationLink(userId);
      if (response.data.success) {
        notification.success({
          message: "We have sent an email verification link to your email.",
        });
        navigate("/auth/login");
      } else {
        notification.error({
          message: response.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  };

  useEffect(() => {
    if (verificationCode) {
      verifyEmailAddress();
    } else {
      navigate("/auth/signup");
    }
  }, [verificationCode, navigate]);

  if (loading) {
    return (
      <Result
        icon={<LoadingOutlined />}
        title="Hang on... We are verifying your email."
      />
    );
  }

  if (!loading && !isVerified && !errorCode) {
    return (
      <EmailVerificationStatus
        status="error"
        title="Verification Failed"
        subTitle={errorMessage}
        btnText="Resend Verification"
        onClick={resendVerificationEmail}
      />
    );
  }

  if (!loading && !isVerified && errorCode === "ALREADY_VERIFIED") {
    return <AlreadyVerified />;
  }

  if (!loading && isVerified) {
    return <VerificationSuccess />;
  }
}

export default VerifyEmail;
