import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllSettings = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_SETTINGS_INIT,
    });
    instance
      .get(`/settings`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_SETTINGS_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_SETTINGS_FAIL,
            payload: res.data?.message || "Failed to get settings",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_SETTINGS_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get settings",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const createNewSetting = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NEW_SETTING_INIT,
    });
    instance
      .post(`/settings`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.CREATE_NEW_SETTING_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.CREATE_NEW_SETTING_FAIL,
            payload: res.data?.message || "Failed to create new setting",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.CREATE_NEW_SETTING_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed create new setting",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getSettingById = (settingId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_SETTING_BY_ID_INIT,
    });
    instance
      .get(`/settings/${settingId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_SETTING_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_SETTING_BY_ID_FAIL,
            payload: res.data?.message || "Failed to get setting",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_SETTING_BY_ID_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to get setting",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const editSetting = (settingId, data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_SETTING_INIT,
    });
    instance
      .put(`/settings/${settingId}`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.EDIT_SETTING_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.EDIT_SETTING_FAIL,
            payload: res.data?.message || "Failed to update setting",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.EDIT_SETTING_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed update setting",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const deleteSetting = (settingId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SETTING_INIT,
    });
    instance
      .delete(`/settings/${settingId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.DELETE_SETTING_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.DELETE_SETTING_FAIL,
            payload: res.data?.message || "Failed to delete new setting",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.DELETE_SETTING_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed delete new setting",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
