export const LOGIN_USER_INIT = "LOGIN_USER_INIT";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const SIGNUP_USER_INIT = "SIGNUP_USER_INIT";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "SIGNUP_USER_FAIL";

export const FORGOT_PASSWORD_INIT = "FORGOT_PASSWORD_INIT";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_INIT = "RESET_PASSWORD_INIT";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const LOGOUT = "LOGOUT";

export const GET_ALL_USERS_INIT = "GET_ALL_USERS_INIT";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const CREATE_NEW_USER_INIT = "CREATE_NEW_USER_INIT";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_FAIL = "CREATE_NEW_USER_FAIL";

export const UPDATE_USER_INIT = "UPDATE_USER_INIT";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_INIT = "DELETE_USER_INIT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const GET_ALL_PROMPTS_INIT = "GET_ALL_PROMPTS_INIT";
export const GET_ALL_PROMPTS_SUCCESS = "GET_ALL_PROMPTS_SUCCESS";
export const GET_ALL_PROMPTS_FAIL = "GET_ALL_PROMPTS_FAIL";

export const CREATE_NEW_PROMPT_INIT = "CREATE_NEW_PROMPT_INIT";
export const CREATE_NEW_PROMPT_SUCCESS = "CREATE_NEW_PROMPT_SUCCESS";
export const CREATE_NEW_PROMPT_FAIL = "CREATE_NEW_PROMPT_FAIL";

export const GET_PROMPT_BY_ID_INIT = "GET_PROMPT_BY_ID_INIT";
export const GET_PROMPT_BY_ID_SUCCESS = "GET_PROMPT_BY_ID_SUCCESS";
export const GET_PROMPT_BY_ID_FAIL = "GET_PROMPT_BY_ID_FAIL";

export const EDIT_PROMPT_INIT = "EDIT_PROMPT_INIT";
export const EDIT_PROMPT_SUCCESS = "EDIT_PROMPT_SUCCESS";
export const EDIT_PROMPT_FAIL = "EDIT_PROMPT_FAIL";

export const DELETE_PROMPT_INIT = "DELETE_PROMPT_INIT";
export const DELETE_PROMPT_SUCCESS = "DELETE_PROMPT_SUCCESS";
export const DELETE_PROMPT_FAIL = "DELETE_PROMPT_FAIL";

export const GET_ALL_SETTINGS_INIT = "GET_ALL_SETTINGS_INIT";
export const GET_ALL_SETTINGS_SUCCESS = "GET_ALL_SETTINGS_SUCCESS";
export const GET_ALL_SETTINGS_FAIL = "GET_ALL_SETTINGS_FAIL";

export const CREATE_NEW_SETTING_INIT = "CREATE_NEW_SETTING_INIT";
export const CREATE_NEW_SETTING_SUCCESS = "CREATE_NEW_SETTING_SUCCESS";
export const CREATE_NEW_SETTING_FAIL = "CREATE_NEW_SETTING_FAIL";

export const GET_SETTING_BY_ID_INIT = "GET_SETTING_BY_ID_INIT";
export const GET_SETTING_BY_ID_SUCCESS = "GET_SETTING_BY_ID_SUCCESS";
export const GET_SETTING_BY_ID_FAIL = "GET_SETTING_BY_ID_FAIL";

export const EDIT_SETTING_INIT = "EDIT_SETTING_INIT";
export const EDIT_SETTING_SUCCESS = "EDIT_SETTING_SUCCESS";
export const EDIT_SETTING_FAIL = "EDIT_SETTING_FAIL";

export const DELETE_SETTING_INIT = "DELETE_SETTING_INIT";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
export const DELETE_SETTING_FAIL = "DELETE_SETTING_FAIL";

export const GET_ALL_HISTORY_INIT = "GET_ALL_HISTORY_INIT";
export const GET_ALL_HISTORY_SUCCESS = "GET_ALL_HISTORY_SUCCESS";
export const GET_ALL_HISTORY_FAIL = "GET_ALL_HISTORY_FAIL";

export const GET_ALL_TAGS_INIT = "GET_ALL_TAGS_INIT";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const GET_ALL_TAGS_FAIL = "GET_ALL_TAGS_FAIL";

export const CREATE_NEW_TAG_INIT = "CREATE_NEW_TAG_INIT";
export const CREATE_NEW_TAG_SUCCESS = "CREATE_NEW_TAG_SUCCESS";
export const CREATE_NEW_TAG_FAIL = "CREATE_NEW_TAG_FAIL";

export const GET_TAG_BY_ID_INIT = "GET_TAG_BY_ID_INIT";
export const GET_TAG_BY_ID_SUCCESS = "GET_TAG_BY_ID_SUCCESS";
export const GET_TAG_BY_ID_FAIL = "GET_TAG_BY_ID_FAIL";

export const EDIT_TAG_INIT = "EDIT_TAG_INIT";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAIL = "EDIT_TAG_FAIL";

export const DELETE_TAG_INIT = "DELETE_TAG_INIT";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL";

export const GET_ALL_DOCUMENTS_INIT = "GET_ALL_DOCUMENTS_INIT";
export const GET_ALL_DOCUMENTS_SUCCESS = "GET_ALL_DOCUMENTS_SUCCESS";
export const GET_ALL_DOCUMENTS_FAIL = "GET_ALL_DOCUMENTS_FAIL";

export const CREATE_NEW_DOCUMENT_INIT = "CREATE_NEW_DOCUMENT_INIT";
export const CREATE_NEW_DOCUMENT_SUCCESS = "CREATE_NEW_DOCUMENT_SUCCESS";
export const CREATE_NEW_DOCUMENT_FAIL = "CREATE_NEW_DOCUMENT_FAIL";

export const GET_DOCUMENT_BY_ID_INIT = "GET_DOCUMENT_BY_ID_INIT";
export const GET_DOCUMENT_BY_ID_SUCCESS = "GET_DOCUMENT_BY_ID_SUCCESS";
export const GET_DOCUMENT_BY_ID_FAIL = "GET_DOCUMENT_BY_ID_FAIL";

export const EDIT_DOCUMENT_INIT = "EDIT_DOCUMENT_INIT";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_FAIL = "EDIT_DOCUMENT_FAIL";

export const DELETE_DOCUMENT_INIT = "DELETE_DOCUMENT_INIT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";

export const GET_ALL_COMPANIES_INIT = "GET_ALL_COMPANIES_INIT";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_FAIL = "GET_ALL_COMPANIES_FAIL";

export const GET_COMPANY_BY_ID_INIT = "GET_COMPANY_BY_ID_INIT";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_FAIL = "GET_COMPANY_BY_ID_FAIL";

export const GET_ALL_SUCCESS_PLANS_INIT = "GET_ALL_SUCCESS_PLANS_INIT";
export const GET_ALL_SUCCESS_PLANS_SUCCESS = "GET_ALL_SUCCESS_PLANS_SUCCESS";
export const GET_ALL_SUCCESS_PLANS_FAIL = "GET_ALL_SUCCESS_PLANS_FAIL";

export const GET_SUCCESS_PLAN_BY_ID_INIT = "GET_SUCCESS_PLAN_BY_ID_INIT";
export const GET_SUCCESS_PLAN_BY_ID_SUCCESS = "GET_SUCCESS_PLAN_BY_ID_SUCCESS";
export const GET_SUCCESS_PLAN_BY_ID_FAIL = "GET_SUCCESS_PLAN_BY_ID_FAIL";

export const UPDATE_SUCCESS_PLAN_BY_ID_INIT = "UPDATE_SUCCESS_PLAN_BY_ID_INIT";
export const UPDATE_SUCCESS_PLAN_BY_ID_SUCCESS = "UPDATE_SUCCESS_PLAN_BY_ID_SUCCESS";
export const UPDATE_SUCCESS_PLAN_BY_ID_FAIL = "UPDATE_SUCCESS_PLAN_BY_ID_FAIL";