import axios from "../Utils/axios";

export const getEstimateSubscription = async (subscriptionId) =>
  axios.get(`/chargebee/subscriptions/${subscriptionId}/estimate-subscription-reactivation`);

export const reactivateSubscription = async (subscriptionId) =>
  axios.put(`/chargebee/subscriptions/${subscriptionId}/renew-subscription`);
  
export const getSubscriptionDetails = async (subscriptionId) =>
  axios.get(`/chargebee/subscriptions/${subscriptionId}`);
  
export const cancelSubscription = async (subscriptionId) =>
  axios.put(`/chargebee/subscriptions/${subscriptionId}/cancel-subscription`);

export const removeScheduledCancellation = async (subscriptionId) =>
    axios.put(`/chargebee/subscriptions/${subscriptionId}/remove_scheduled_cancellation`);

export const getRefundDetails = async (data) => 
    axios.post(`/chargebee/refund-details`, {...data})

export const initiateRefund = async (data,invoiceId) => 
    axios.post(`/chargebee/invoices/${invoiceId}/refund`,{...data})
