import React from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./Login.scss";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { login, setToken } from "../../Services/authentication";
import { getCurrentUserDetails } from "../../Services/user";

const LoginPage = () => {
  const { loading } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const onFinish = async (values) => {
    try {
      const res = await login(values)
      await setToken(res.data.token);
      const userData = await getCurrentUserDetails(true)
      notification.success({ message: "Successfully logged in!!" });
      const redirectURL = queryParams.get('redirect_url');
      console.log('onFinish  redirectURL:', redirectURL)
      if (redirectURL) {
        navigate(redirectURL, {replace: true})
      } else {
        navigate(userData.user.role === 'user' ?'/companies' : '/', {replace: true})
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message || error?.message || "Failed to login" });
    }
  };

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <h2>Login</h2>
        <Form size="large" name="login" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <div className="forgot-password-wrap">
            <a className="login-form-forgot" href="/auth/forgot-password">
              Forgot password
            </a>
          </div>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        Don't have an account <Link to="/auth/signup">register now!</Link>
      </div>
    </div>
  );
};

export default LoginPage;
