import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  documents: [],
  loading: false,
  paginationData: null,
  documentDetails: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_DOCUMENTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action?.payload?.documents,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_DOCUMENT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_DOCUMENT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_DOCUMENT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documentDetails: action?.payload?.document,
      };
    case actionTypes.EDIT_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_DOCUMENT_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        documentDetails: action?.payload?.document,
      };
    case actionTypes.GET_DOCUMENT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
