import React, { useEffect, useState } from "react";
import { Breadcrumb, Space, Button, Table } from "antd";
import "./Companies.scss";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanies } from "../../Redux/Actions/CompanyActions";

const Companies = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { companies, loading, paginationData } = useSelector(
    (state) => state?.company
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    dispatch(getAllCompanies({ page: page, limit: pageSize }));
  }, []);

  const handleView = (company) => {
    navigation(`/companies/${company.id}`);
  };

  const onPaginationChange = (page, pageSize) => {
    dispatch(
      getAllCompanies({
        page,
        limit: pageSize,
      })
    );
    setPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: "Website URL",
      dataIndex: "website",
      width: 400,
    },
    {
      title: "Action",
      render: (_, data) => (
        <Space size={"small"}>
          <Button
            className={"action_btn"}
            type="link"
            onClick={() => handleView(data)}
          >
            View
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  return (
    <div className="companies-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Companies</Breadcrumb.Item>
      </Breadcrumb>
      <div className="companies-table">
        <h4>Companies</h4>
        <Table
          rowKey="id"
          className="data-table"
          columns={columns}
          dataSource={companies}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: false,
            total: paginationData?.total,
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              onPaginationChange(page, pageSize);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Companies;
