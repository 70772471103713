import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Space,
  Button,
  Table,
  notification,
  Popconfirm,
  Modal,
  Form,
  Input,
  Upload,
  Tag,
  Select,
} from "antd";
import {
  deleteDocument,
  getAllDocuments,
  createNewDocument,
  editDocument as updateDocument,
} from "../../Redux/Actions/DocumentActions";
import "./Documents.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getAllTags } from "../../Redux/Actions/TagActions";

const Documents = () => {
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state?.tag);
  const { documents, loading, paginationData } = useSelector(
    (state) => state.document
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllDocuments({ page, limit: pageSize }));
    dispatch(getAllTags());
  }, [page, pageSize]);

  const handleReset = () => {
    setPage(1);
    dispatch(getAllDocuments({ page: 1, limit: pageSize }));
  };

  const handleDelete = (documentId) => {
    dispatch(
      deleteDocument(
        documentId,
        () => {
          notification.success({ message: "Document deleted successfully!" });
          handleReset();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to delete document",
          });
        }
      )
    );
  };

  const handleCreate = () => {
    form.resetFields();
    setModalVisible(true);
  };

  const handleEdit = (document) => {
    form.setFieldsValue({
      id: document?.id,
      document_file: document?.file_url,
      tags: document?.tags?.map((tag) => tag?.id),
    });
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const handleModalSubmit = () => {
    form.validateFields().then((values) => {
      if (values?.id) {
        // Update document
        const formData = new FormData();
        if (values?.document_file?.file) {
          formData.append("document_file", values?.document_file?.file);
          formData.append("file_name", values?.document_file?.file?.name);
        }
        formData.append("tags", values?.tags);
        dispatch(
          updateDocument(
            values?.id,
            formData,
            () => {
              notification.success({
                message: "Document updated successfully!",
              });
              handleReset();
              setModalVisible(false);
            },
            (errorMessage) => {
              notification.error({
                message: errorMessage || "Failed to update document",
              });
            }
          )
        );
      } else {
        // Create document
        const formData = new FormData();
        formData.append("document_file", values?.document_file?.file);
        formData.append("file_name", values?.document_file?.file?.name);
        formData.append("tags", values?.tags);
        dispatch(
          createNewDocument(
            formData,
            () => {
              notification.success({
                message: "Document created successfully!",
              });
              handleReset();
              setModalVisible(false);
            },
            (errorMessage) => {
              notification.error({
                message: errorMessage || "Failed to create document",
              });
            }
          )
        );
      }
    });
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      width: 200,
    },
    {
      title: "File URL",
      dataIndex: "file_url",
      width: 300,
    },
    {
      title: "GUID",
      dataIndex: "guid",
      with: 100,
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return <Tag key={tag?.id}>{tag?.name.toUpperCase()}</Tag>;
          })}
        </>
      ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <Space size={"small"}>
          <Button
            className={"action_btn"}
            type="link"
            onClick={() => handleEdit(data)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this document?"
            onConfirm={() => handleDelete(data?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={"action_btn"} type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  return (
    <div className="documents-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Documents</Breadcrumb.Item>
      </Breadcrumb>
      <div className="documents-table">
        <h4>Documents</h4>
        <Row gutter={24} justify="space-between">
          <Col offset={22} span={2}>
            <Button onClick={handleCreate}>Create</Button>
          </Col>
        </Row>
        <Table
          rowKey="id"
          className="data-table"
          columns={columns}
          dataSource={documents}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: paginationData?.total,
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </div>
      <Modal
        title="Document"
        visible={modalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalSubmit}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="document_file"
            label="Document File"
            rules={[{ required: true, message: "Please Select File" }]}
          >
            <Upload
              listType="picture"
              action={null}
              multiple={false}
              beforeUpload={() => false}
              accept=".doc,.docx,application/pdf"
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="tags" label="Tags">
            <Select
              style={{ width: 260 }}
              mode="tags"
              placeholder="Select tags"
            >
              {tags?.map((tag) => {
                return (
                  <Select.Option value={tag?.id}>{tag?.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Documents;
