import { UploadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Spin,
  notification,
  Switch,
  InputNumber,
  Upload,
  Radio,
  Checkbox,
} from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { editPrompt, getPromptById } from "../../Redux/Actions/PromptActions";
import "./PromptDetails.scss";

const { TextArea } = Input;

function PromptDetails() {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { promptDetails, loading } = useSelector((state) => state?.prompt);

  useEffect(() => {
    dispatch(getPromptById(params?.promptId));
  }, [params?.promptId]);

  useEffect(() => {
    form.setFieldValue("name", promptDetails?.name);
    form.setFieldValue("prompt", promptDetails?.prompt);
    form.setFieldValue("is_default", promptDetails?.is_default);
    form.setFieldValue("order", promptDetails?.order);
    form.setFieldValue("is_active", promptDetails?.is_active);
    form.setFieldValue("ai_tool", promptDetails?.ai_tool?.split(","));
    form.setFieldValue("icon", promptDetails?.icon);
  }, [promptDetails]);

  const handleFormSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("prompt", values?.prompt);
    formData.append("icon", values?.icon?.file);
    formData.append("is_active", values?.is_active);
    formData.append("is_default", values?.is_default);
    !!values?.order && formData.append("order", values?.order);
    formData.append("ai_tool", values?.ai_tool?.join(","));
    dispatch(
      editPrompt(
        promptDetails?.id,
        formData,
        () => {
          notification.success({ message: "Prompt updated successfully!!" });
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to update prompt",
          });
        }
      )
    );
  };

  const isDefault = Form.useWatch("is_default", form);

  return (
    <div className="prompt-details-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/prompts">{`Prompts`}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{promptDetails?.id}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className="prompt-details-wrap">
          <Row>
            <Col span={20} className="content-col">
              <div className="header-section">
                <span className="title">{promptDetails?.name}</span>
              </div>
              <Divider />
              <div className="content-section">
                <Form
                  form={form}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  onFinish={handleFormSubmit}
                  layout="horizontal"
                  autoComplete="off"
                >
                  <Row align={"middle"} justify={"center"}>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter the prompt name",
                          },
                        ]}
                        name={"name"}
                        label="Prompt Name"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter the prompt value",
                          },
                        ]}
                        name={"prompt"}
                        label="Prompt"
                      >
                        <TextArea rows={5}  />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Icon is required",
                          },
                        ]}
                        label="Icon"
                        name="icon"
                      >
                        <Upload
                          listType="picture"
                          action={null}
                          multiple={false}
                          beforeUpload={() => false}
                          accept={"image/*"}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Ai tool is required",
                          },
                        ]}
                        label="AI tool"
                        name="ai_tool"
                      >
                        <Checkbox.Group
                          options={[
                            { label: "Chat GPT", value: "chat_gpt" },
                            { label: "Bard", value: "bard" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        valuePropName="checked"
                        name={"is_active"}
                        label="Active"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        valuePropName="checked"
                        name={"is_default"}
                        label="Default"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>
                    {isDefault && (
                      <Col span={24}>
                        <Form.Item name={"order"} label="Order">
                          <InputNumber />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={24}>
                      <Form.Item label="Created">
                        <Input
                          disabled
                          value={moment(promptDetails?.createdAt)?.format(
                            "YYYY-MM-DD hh:mm:ss"
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button htmlType="submit" type="primary">
                        Save Prompt
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}

export default PromptDetails;
