import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result} from "antd";
import { getCurrentUserDetails } from "../Services/user";

const UserProtectedRoute = ({ children }) => {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const getUserDetails = async () => {
    setUser(await getCurrentUserDetails());
  }

  useEffect(() => {
    getUserDetails();
  }, [])  

  if (user?.role !== "user") {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    );
  }

  return children;
};

export default UserProtectedRoute;
