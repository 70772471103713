import instance from "../../Utils/axios";
import { persistor } from "../store";
import * as actionTypes from "./ActionTypes";

export const loginUser = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_USER_INIT,
    });
    instance
      .post(`/auth/login`, { ...data })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.LOGIN_USER_SUCCESS,
            payload: res.data,
          });
          onSuccess();
        } else {
          dispatch({
            type: actionTypes.LOGIN_USER_FAIL,
            payload: res.data?.message || "Failed to login",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.LOGIN_USER_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to login",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const signupUser = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SIGNUP_USER_INIT,
    });
    instance
      .post(`/auth/signup`, { ...data })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.SIGNUP_USER_SUCCESS,
            payload: res.data,
          });
          onSuccess();
        } else {
          dispatch({
            type: actionTypes.SIGNUP_USER_FAIL,
            payload: res.data?.message || "Failed to signup",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SIGNUP_USER_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed to signup",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const forgotPassword = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FORGOT_PASSWORD_INIT,
    });
    instance
      .post(`/auth/forgot-password`, { ...data })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.FORGOT_PASSWORD_SUCCESS,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: actionTypes.FORGOT_PASSWORD_FAIL,
            payload: res.data?.message || "Failed to send reset password email",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.FORGOT_PASSWORD_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed to send reset password email",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const resetPassword = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RESET_PASSWORD_INIT,
    });
    instance
      .post(`/auth/reset-password`, { ...data })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.RESET_PASSWORD_SUCCESS,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: actionTypes.RESET_PASSWORD_FAIL,
            payload: res.data?.message || "Failed to reset new password",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.RESET_PASSWORD_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed to reset new password",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOGOUT });
    persistor.purge();
  };
};
