import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  prompts: [],
  loading: false,
  paginationData: null,
  promptDetails: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROMPTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_PROMPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        prompts: action?.payload?.prompts,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_PROMPTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_PROMPT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_NEW_PROMPT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_PROMPT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_PROMPT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_PROMPT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_PROMPT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_PROMPT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_PROMPT_SUCCESS:
      return {
        ...state,
        loading: false,
        promptDetails: action?.payload?.prompt,
      };
    case actionTypes.EDIT_PROMPT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_PROMPT_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PROMPT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        promptDetails: action?.payload?.prompt,
      };
    case actionTypes.GET_PROMPT_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
