import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  companies: [],
  loading: false,
  paginationData: null,
  companyDetails: null,
  successPlans: [],
  successPlanDetails: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_COMPANIES_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action?.payload?.companies,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_COMPANIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_COMPANY_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        companyDetails: action?.payload?.company,
      };
    case actionTypes.GET_COMPANY_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_ALL_SUCCESS_PLANS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_SUCCESS_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        successPlans: action?.payload?.successPlans,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_SUCCESS_PLANS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SUCCESS_PLAN_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SUCCESS_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        successPlanDetails: action?.payload?.successPlan,
      };
    case actionTypes.GET_SUCCESS_PLAN_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        successPlanDetails: action?.payload?.successPlan,
      };
    case actionTypes.UPDATE_SUCCESS_PLAN_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
