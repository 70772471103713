import React from "react";
import { Form, Input, Button, notification } from "antd";
import "./Signup.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { signupUser } from "../../Redux/Actions/AuthActions";
import { login, setToken } from "../../Services/authentication";
import { getCurrentUserDetails } from "../../Services/user";

export const SignupForm = ({ onFinish, loading }) => {
  const passwordValidator = (_, value) => {
    if (value && value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters")
      );
    }
    return Promise.resolve();
  };
  return (
    <div className="signup-page">
      <div className="signup-wrapper">
        <h2>Signup</h2>
        <Form size="large" name="signup" onFinish={onFinish}>
          <Form.Item
            name="firstName"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter your first name",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter your last name",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              {
                validator: passwordValidator,
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="signup-form-button"
            >
              Signup
            </Button>
          </Form.Item>
        </Form>
        If you have already an account <a href="/auth/login">Login</a>
      </div>
    </div>
  );
};

const SignupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.auth);

  const loginUser = async ({ email, password }) => {
    try {
      const res = await login({
        email,
        password,
      });

      await setToken(res.data.token);

      const userData = await getCurrentUserDetails(true);

      navigate(userData?.user?.role === "user" ? "/companies" : "/", {
        replace: true,
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

  const onFinish = (values) => {
    dispatch(
      signupUser(
        values,
        async () => {
          notification.success({
            message:
              "Registration successful. We have sent an email to your email address for verification.",
          });
          
          await loginUser(values);
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to signup user",
          });
        }
      )
    );
  };



  return (
    <SignupForm onFinish={onFinish} loading={loading}/>
  );
};

export default SignupPage;
