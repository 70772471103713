import {
  Breadcrumb,
  Row,
  Col,
  Space,
  Button,
  Table,
  notification,
  Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./Settings.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  deleteSetting,
  getAllSettings,
} from "../../Redux/Actions/SettingActions";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { settings, loading, paginationData } = useSelector(
    (state) => state?.setting
  );
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    dispatch(getAllSettings({ page: page, limit: pageSize }));
  }, [page, pageSize]);

  const onPaginationChange = (page, pageSize) => {
    setpage(page);
    setPageSize(pageSize);
  };

  const onDeleteSetting = (settingId) => {
    dispatch(
      deleteSetting(
        settingId,
        () => {
          notification.success({ message: "Setting deleted successfully!!" });
          dispatch(getAllSettings({ page: 1, limit: pageSize }));
          setpage(1);
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to delete setting",
          });
        }
      )
    );
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "service_name",
      render: (text, data) => (
        <span
          onClick={() => navigate(`/settings/${data?.id}`)}
          style={{ color: "#391085", cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Api Key",
      dataIndex: "api_key",
    },
    {
      title: "Api Secret",
      dataIndex: "api_secret",
      render: (text) => text || "N/A",
    },
    {
      title: "Action",
      render: (_, data) => (
        <Space size={"small"}>
          <Button
            onClick={() => navigate(`/settings/${data?.id}`)}
            className={"action_btn"}
            type="link"
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this setting?"
            onConfirm={() => onDeleteSetting(data?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={"action_btn"} type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="settings-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>{`Settings`}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="settings-table">
        <h4>Settings</h4>
        <Row gutter={24} justify="space-between">
          <Col offset={22} span={2}>
            <Button onClick={() => navigate("/settings/create")}>Create</Button>
          </Col>
        </Row>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={"id"}
          className="data-table"
          columns={columns}
          dataSource={settings}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: paginationData?.total,
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              onPaginationChange(page, pageSize);
            },
          }}
        />
      </div>
    </div>
  );
}

export default Settings;
