import React, { useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, Upload, Modal } from "antd";

import "./UserCreateModal.scss";
import { useSelector } from "react-redux";

function UserCreateModal(props) {
  const {
    isOpen,
    handleOpenChange,
    handleCreateUser,
    isEditMode,
    intialValues,
    handleUpdateUser,
    openedFromProfile,
  } = props;
  const { loading } = useSelector((state) => state?.user);

  const onFinish = (values) => {
    !isEditMode ? handleCreateUser(values) : handleUpdateUser(values);
  };

  return (
    <Modal
      visible={isOpen}
      title={isEditMode ? "Update User" : "Create User"}
      centered
      destroyOnClose
      footer={null}
      onCancel={() => handleOpenChange(false)}
      onOk={() => handleOpenChange(false)}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={onFinish}
        initialValues={intialValues}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please enter the first name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Please enter the last name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Organization"
          name="organization"
          // rules={[{ required: true, message: "Please enter the organization" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter the email address" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input />
        </Form.Item>
        {(intialValues?.role === 'user' || !isEditMode) && (
          <Form.Item
            label="Password"
            name="password"
            rules={!isEditMode ? [
                    { required: true, message: "Please enter the password" },
                    { min: 8, message: "password must be atleast 8 character" },
            ]: []}
          >
            <Input.Password />
          </Form.Item>
        )}
        {!openedFromProfile && (
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please select a role option" }]}
          >
            <Select>
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="user">User</Select.Option>
            </Select>
          </Form.Item>
         )}
        <Form.Item label="Avatar" name="avatar">
          <Upload
            listType="picture"
            action={null}
            multiple={false}
            beforeUpload={() => false}
            accept={"image/*"}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            {isEditMode ? "Update User" : "Create User"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UserCreateModal;
