import {
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Button,
  Table,
  notification,
  Popconfirm,
  Avatar,
} from "antd";
import Search from "antd/lib/input/Search";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Prompts.scss";
import { useDispatch } from "react-redux";
import { deletePrompt, getAllPrompts } from "../../Redux/Actions/PromptActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
const { RangePicker } = DatePicker;

function Prompts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { prompts, loading, paginationData } = useSelector(
    (state) => state?.prompt
  );
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState();
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    dispatch(getAllPrompts({ page: page, limit: pageSize }));
  }, []);

  const handleSearch = () => {
    dispatch(
      getAllPrompts({
        search: searchValue,
        ...(dateRange?.length === 2 && {
          start_date: moment(dateRange?.[0]).format("YYYY-MM-DD"),
          end_date: moment(dateRange?.[1]).format("YYYY-MM-DD"),
        }),
        page: 1,
        limit: pageSize,
      })
    );
    setpage(1);
  };

  const onPaginationChange = (page, pageSize) => {
    dispatch(
      getAllPrompts({
        search: searchValue,
        ...(dateRange?.length === 2 && {
          start_date: moment(dateRange?.[0]).format("YYYY-MM-DD"),
          end_date: moment(dateRange?.[1]).format("YYYY-MM-DD"),
        }),
        page,
        limit: pageSize,
      })
    );
    setpage(page);
    setPageSize(pageSize);
  };

  const handleReset = () => {
    dispatch(
      getAllPrompts({
        page: 1,
        limit: pageSize,
      })
    );
    setpage(1);
    setSearchValue();
    setDateRange();
  };

  const onDeletePrompt = (promptId) => {
    dispatch(
      deletePrompt(
        promptId,
        () => {
          notification.success({ message: "Prompt deleted successfully!!" });
          handleReset();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to delete prompt",
          });
        }
      )
    );
  };

  const columns = [
    {
      title: "Prompt Name",
      dataIndex: "name",
      render: (text, data) => (
        <span
          onClick={() => navigate(`/prompts/${data?.id}`)}
          style={{ color: "#391085", cursor: "pointer" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Prompt",
      dataIndex: "prompt",
      width: 260,
    },
    {
      title: "Icon",
      dataIndex: "icon",
      render: (value, data) => <Avatar src={value}>{data?.name?.[0]}</Avatar>,
    },
    {
      title: "AI tool",
      dataIndex: "ai_tool",
      render: (value) => value?.split(",")?.join(" | "),
    },
    {
      title: "Default",
      dataIndex: "is_default",
      render: (value) => (!!value ? "Yes" : "No"),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      render: (value) => (!!value ? "Yes" : "No"),
    },
    {
      title: "Order",
      dataIndex: "order",
      render: (value) => value ?? "-",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text) => moment(text)?.format("YYYY-MM-DD hh:mm:ss"),
    },
    {
      title: "Action",
      render: (_, data) => (
        <Space size={"small"}>
          <Button
            onClick={() => navigate(`/prompts/${data?.id}`)}
            className={"action_btn"}
            type="link"
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this prompt?"
            onConfirm={() => onDeletePrompt(data?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className={"action_btn"} type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="prompts-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>{`Prompts`}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="prompts-table">
        <h4>Prompts</h4>
        <Row gutter={24} justify="space-between">
          <Col span={20}>
            <Row gutter={30}>
              <Col span={6}>
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target?.value)}
                  placeholder="Search"
                />
              </Col>
              <Col span={8}>
                <RangePicker
                  value={dateRange}
                  onChange={(values) => setDateRange(values)}
                />
              </Col>
              <Col span={4}>
                <Space>
                  <Button type="primary" onClick={handleSearch}>
                    Search
                  </Button>
                  <Button onClick={handleReset}>Reset</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col offset={2} span={2}>
            <Button onClick={() => navigate("/prompts/create")}>Create</Button>
          </Col>
        </Row>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={"id"}
          className="data-table"
          columns={columns}
          dataSource={prompts}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: paginationData?.total,
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              onPaginationChange(page, pageSize);
            },
          }}
        />
      </div>
    </div>
  );
}

export default Prompts;
