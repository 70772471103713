import {
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Button,
  Table,
  Avatar,
  notification,
  Popconfirm,
} from "antd";
import Search from "antd/lib/input/Search";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Users.scss";
import UserCreateModal from "../../Component/UserCreateModal/UserCreateModal";
import { useDispatch } from "react-redux";
import {
  createNewUser,
  deleteUser,
  editUser,
  getAllUsers,
} from "../../Redux/Actions/UserActions";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

function Users() {
  const dispatch = useDispatch();
  const { users, loading, paginationData } = useSelector(
    (state) => state?.user
  );
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState();
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [editUserData, setEdituser] = useState();

  useEffect(() => {
    dispatch(getAllUsers({ page: page, limit: pageSize }));
  }, []);

  const handleSearch = () => {
    dispatch(
      getAllUsers({
        search: searchValue,
        ...(dateRange?.length === 2 && {
          start_date: moment(dateRange?.[0]).format("YYYY-MM-DD"),
          end_date: moment(dateRange?.[1]).format("YYYY-MM-DD"),
        }),
        page: 1,
        limit: pageSize,
      })
    );
    setpage(1);
  };

  const onPaginationChange = (page, pageSize) => {
    dispatch(
      getAllUsers({
        search: searchValue,
        ...(dateRange?.length === 2 && {
          start_date: moment(dateRange?.[0]).format("YYYY-MM-DD"),
          end_date: moment(dateRange?.[1]).format("YYYY-MM-DD"),
        }),
        page,
        limit: pageSize,
      })
    );
    setpage(page);
    setPageSize(pageSize);
  };

  const handleReset = () => {
    dispatch(
      getAllUsers({
        page: 1,
        limit: pageSize,
      })
    );
    setpage(1);
    setSearchValue();
    setDateRange();
  };

  const handleCreateUser = (values) => {
    const formData = new FormData();
    formData.append("firstName", values?.firstName);
    formData.append("lastName", values?.lastName);
    formData.append("organization", values?.organization);
    formData.append("email", values?.email);
    formData.append("role", values?.role);
    formData.append("password", values?.password);
    formData.append("avatar", values?.avatar?.file);
    dispatch(
      createNewUser(
        formData,
        () => {
          notification.success({ message: "User created successfully!!" });
          setCreateUserModalOpen(false);
          handleReset();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to create user",
          });
        }
      )
    );
  };

  const handleUpdateUser = (values) => {
    const formData = new FormData();
    formData.append("firstName", values?.firstName);
    formData.append("lastName", values?.lastName);
    formData.append("organization", values?.organization || "");
    formData.append("email", values?.email);
    formData.append("role", values?.role);
    formData.append("avatar", values?.avatar?.file);
    !!values?.password && formData.append("password", values?.password);
    dispatch(
      editUser(
        editUserData?.id,
        formData,
        () => {
          notification.success({ message: "User updated successfully!!" });
          setCreateUserModalOpen(false);
          setEdituser();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to update user",
          });
        }
      )
    );
  };

  const onDeleteUser = (userId) => {
    dispatch(
      deleteUser(
        userId,
        () => {
          notification.success({ message: "User deleted successfully!!" });
          handleReset();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to delete user",
          });
        }
      )
    );
  };

  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (avatar, data) => (
        <Avatar src={avatar}>
          {data?.firstName?.[0]?.toUpperCase()}
          {data?.lastName?.[0]?.toUpperCase()}
        </Avatar>
      ),
      width: 60,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Organization",
      dataIndex: "organization",
      render: (text) => text || "-",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Joined At",
      dataIndex: "createdAt",
      render: (text) => moment(text)?.format("YYYY-MM-DD hh:mm:ss"),
    },
    {
      title: "Role",
      dataIndex: "role",
    },

    {
      title: "Action",
      render: (_, data) => (
        <Space size={"small"}>
          <Button
            onClick={() => {
              const userData = { ...data };
              if (userData?.password) {
                delete userData.password;
              }
              setEdituser(userData);
              setCreateUserModalOpen(true);
            }}
            className={"action_btn"}
            type="link"
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => onDeleteUser(data?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: "center",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="users-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>{`Users`}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="users-table">
        <h4>Users</h4>
        <Row gutter={24} justify="space-between">
          <Col span={20}>
            <Row gutter={30}>
              <Col span={6}>
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target?.value)}
                  placeholder="Search"
                />
              </Col>
              {/* <Col span={6}>
                <Input placeholder="Please pick an address" />
              </Col> */}
              <Col span={8}>
                <RangePicker
                  value={dateRange}
                  onChange={(values) => setDateRange(values)}
                />
              </Col>
              <Col span={4}>
                <Space>
                  <Button type="primary" onClick={handleSearch}>
                    Search
                  </Button>
                  <Button onClick={handleReset}>Reset</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col offset={2} span={2}>
            <Button
              onClick={() => {
                setEdituser();
                setCreateUserModalOpen(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={"id"}
          className="data-table"
          columns={columns}
          dataSource={users}
          loading={loading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: paginationData?.total,
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              onPaginationChange(page, pageSize);
            },
          }}
        />
      </div>
      <UserCreateModal
        isOpen={createUserModalOpen}
        handleCreateUser={handleCreateUser}
        handleOpenChange={(open) => setCreateUserModalOpen(open)}
        isEditMode={!!editUserData}
        intialValues={editUserData}
        handleUpdateUser={handleUpdateUser}
      />
    </div>
  );
}

export default Users;
