import {
  Breadcrumb,
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  Spin,
  notification,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  editSetting,
  getSettingById,
} from "../../Redux/Actions/SettingActions";
import "./SettingDetails.scss";

function SettingDetails() {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { settingDetails, loading } = useSelector((state) => state?.setting);

  useEffect(() => {
    dispatch(getSettingById(params?.settingId));
  }, [params?.settingId]);

  useEffect(() => {
    form.setFieldValue("service_name", settingDetails?.service_name);
    form.setFieldValue("api_key", settingDetails?.api_key);
    form.setFieldValue("api_secret", settingDetails?.api_secret);
  }, [settingDetails]);

  const handleFormSubmit = (values) => {
    dispatch(
      editSetting(
        settingDetails?.id,
        values,
        () => {
          notification.success({ message: "Setting updated successfully!!" });
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to update setting",
          });
        }
      )
    );
  };

  return (
    <div className="setting-details-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/settings">{`Settings`}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{settingDetails?.id}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading}>
        <div className="setting-details-wrap">
          <Row>
            <Col span={20} className="content-col">
              <div className="header-section">
                <span className="title">{settingDetails?.service_name}</span>
              </div>
              <Divider />
              <div className="content-section">
                <Form
                  form={form}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  onFinish={handleFormSubmit}
                  layout="horizontal"
                  autoComplete="off"
                >
                  <Row align={"middle"} justify={"center"}>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter the service name",
                          },
                        ]}
                        name={"service_name"}
                        label="Service Name"
                      >
                        <Select placeholder={"Please select service"}>
                          <Select.Option value="Chat GPT">
                            Chat GPT
                          </Select.Option>
                          <Select.Option value="SalesForce">
                            SalesForce
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter the api key",
                          },
                        ]}
                        name={"api_key"}
                        label="Api Key"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={"api_secret"} label="Api Secret">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                      >
                        Save Setting
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}

export default SettingDetails;
