import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  tags: [],
  loading: false,
  paginationData: null,
  tagDetails: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_TAGS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action?.payload?.tags,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_TAGS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_TAG_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_NEW_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_TAG_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_TAG_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_TAG_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_TAG_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        tagDetails: action?.payload?.tag,
      };
    case actionTypes.EDIT_TAG_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_TAG_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TAG_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        tagDetails: action?.payload?.tag,
      };
    case actionTypes.GET_TAG_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
