import React from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./ResetPassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Redux/Actions/AuthActions";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.auth);
  const navigate = useNavigate();

  const onFinish = (values) => {
    dispatch(
      resetPassword(
        { ...values, reset_token: token }, // Include the reset token in the request payload
        () => {
          notification.success({
            message: "Password reset successfully!",
          });
          navigate("/auth/login");
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to reset password",
          });
        }
      )
    );
  };

  return (
    <div className="reset-password-page">
      <div className="reset-password-wrapper">
        <h2>Reset Password</h2>
        <Form size="large" name="resetPassword" onFinish={onFinish}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
            ]}
          >
            <Input.Password
              prefix={<UserOutlined />}
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<UserOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="reset-password-form-button"
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        Back to <a href="/auth/login">Login</a>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
