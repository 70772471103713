import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  users: [],
  loading: false,
  paginationData: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action?.payload?.users,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state?.users?.map((item) => {
          if (item?.id === action?.payload?.user?.id) {
            return action?.payload?.user;
          } else {
            return item;
          }
        }),
      };
    case actionTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
