import {
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Button,
  Divider,
  Pagination,
} from "antd";
import moment from "moment";
import Search from "antd/lib/input/Search";
import React, { useState, useEffect } from "react";
import "./History.scss";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllHistory } from "../../Redux/Actions/HistoryActions";
const { RangePicker } = DatePicker;

function History() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { histories, loading, paginationData } = useSelector(
    (state) => state?.history
  );
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState();
  const [page, setpage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    dispatch(getAllHistory({ page: page, limit: pageSize }));
  }, []);

  const handleSearch = () => {
    dispatch(
      getAllHistory({
        search: searchValue,
        ...(dateRange?.length === 2 && {
          start_date: moment(dateRange?.[0]).format("YYYY-MM-DD"),
          end_date: moment(dateRange?.[1]).format("YYYY-MM-DD"),
        }),
        page: 1,
        limit: pageSize,
      })
    );
    setpage(1);
  };

  const onPaginationChange = (page, pageSize) => {
    dispatch(
      getAllHistory({
        search: searchValue,
        ...(dateRange?.length === 2 && {
          start_date: moment(dateRange?.[0]).format("YYYY-MM-DD"),
          end_date: moment(dateRange?.[1]).format("YYYY-MM-DD"),
        }),
        page,
        limit: pageSize,
      })
    );
    setpage(page);
    setPageSize(pageSize);
  };

  const handleReset = () => {
    dispatch(
      getAllHistory({
        page: 1,
        limit: pageSize,
      })
    );
    setpage(1);
    setSearchValue();
    setDateRange();
  };

  return (
    <div className="history-main-wrap">
      <Breadcrumb>
        <Breadcrumb.Item>{`Home`}</Breadcrumb.Item>
        <Breadcrumb.Item>{`History`}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="history-list">
        <h4>History</h4>
        <Row gutter={24} justify="space-between">
          <Col span={24}>
            <Row gutter={30}>
              <Col span={6}>
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target?.value)}
                  placeholder="Search"
                />
              </Col>
              <Col span={8}>
                <RangePicker
                  value={dateRange}
                  onChange={(values) => setDateRange(values)}
                />
              </Col>
              <Col span={4}>
                <Space>
                  <Button type="primary" onClick={handleSearch}>
                    Search
                  </Button>
                  <Button onClick={handleReset}>Reset</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="history-content">
          {histories?.map((item) => {
            return (
              <div
                className="history-item"
                onClick={() =>
                  navigate(`/history/${item?.name}`, { state: item })
                }
              >
                <div className="history-item-top">
                  <span className="history-name">{item?.name}</span>
                  <span>{moment(item?.updatedAt)?.fromNow()}</span>
                </div>
                {/* <span className="prompt-details">
                  {item?.prompt} Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                  sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </span> */}
                <Divider className="divider" />
              </div>
            );
          })}
        </div>
        <Pagination
          style={{ textAlign: "end" }}
          showSizeChanger={true}
          showQuickJumper={true}
          total={paginationData?.total}
          current={page}
          pageSize={pageSize}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          onChange={(page, pageSize) => {
            onPaginationChange(page, pageSize);
          }}
        />
      </div>
    </div>
  );
}

export default History;
