import axios from "../Utils/axios";

export const verifyEmail = async (code) =>
  axios.post("/auth/verify-email", { code });

export const requestEmailVerificationLink = async (userId) =>
  axios.post("/auth/resend-email-verification", { userId });

export const login = async (data) => axios.post("/auth/login", { ...data });
export const setToken = async (data) => localStorage.setItem("token", JSON.stringify(data));
export const getToken = () => JSON.parse(localStorage.getItem("token") || null);

export const logout = async () => localStorage.clear();