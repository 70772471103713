import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  settings: [],
  loading: false,
  paginationData: null,
  settingDetails: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_SETTINGS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action?.payload?.settings,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_SETTING_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_NEW_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_NEW_SETTING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_SETTING_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_SETTING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_SETTING_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        settingDetails: action?.payload?.setting,
      };
    case actionTypes.EDIT_SETTING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SETTING_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SETTING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        settingDetails: action?.payload?.setting,
      };
    case actionTypes.GET_SETTING_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
