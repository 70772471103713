import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthReducer from "./Reducers/AuthReducer";
import UserReducer from "./Reducers/UserReducer";
import PromptRedcuer from "./Reducers/PromptReducer";
import SettingRedcuer from "./Reducers/SettingReducer";
import HistoryRedcuer from "./Reducers/HistoryReducer";
import TagReducer from "./Reducers/TagReducer";
import DocumentReducer from "./Reducers/DocumentReducer";
import CompanyReducer from "./Reducers/CompanyReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  prompt: PromptRedcuer,
  setting: SettingRedcuer,
  history: HistoryRedcuer,
  tag: TagReducer,
  document: DocumentReducer,
  company: CompanyReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
