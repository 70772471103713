import { Button, Result, notification,Modal } from "antd";
import { useState } from "react";
import { getEstimateSubscription, getSubscriptionDetails, reactivateSubscription } from "../../Services/subscription";

function ReactivateSubscription({subscriptionId}) {
    const [estimateModal, setEstimateModal] = useState(false)
    const [estimates, setEstimates] = useState(null)
    const [reactivateLoader, setReactivateLoader] = useState(false)
    
    const handleRenew = async () => {
        try {
          setReactivateLoader(true);
          const response = await getEstimateSubscription(subscriptionId);
          if (response.data.success) {
            if(response?.data?.estimates){
              setEstimates(response?.data?.estimates);
            }
            setReactivateLoader(false);
            setEstimateModal(true)
          } else {
            notification.error({
              message: response.data?.message,
            });
            setReactivateLoader(false);
          }
        } catch (error) {
          notification.error({
            message: error.response?.data?.message || error.message,
          });
          setReactivateLoader(false);
        }
    }
    
    const onReactivate = async () => {
        try {
          setReactivateLoader(true);
          const response = await reactivateSubscription(subscriptionId);
          if (response.data.success) {
            notification.success({message: response.data.message || "Subscription reactivated successfully"})
            setReactivateLoader(false);
            setEstimateModal(false)
            await getSubscriptionDetails(subscriptionId)
            window.location.reload();
          } else {
            notification.error({
              message: response.data?.message,
            });
            setReactivateLoader(false);
            setEstimateModal(false)
          }
        } catch (error) {
          notification.error({
            message: error.response?.data?.message || error.message,
          });
          setReactivateLoader(false);
          setEstimateModal(false)
        }
    }

    return (
        <>
          <Result
            title="Oops!"
            subTitle="It appears that your subscription plan has been cancelled."
            extra={
              <Button type="primary" onClick={handleRenew} loading={reactivateLoader}>
                Restart
              </Button>
            }
          />
          {estimateModal && estimates != null && <Modal
            title="Subscription Estimates"
            visible={estimateModal}
            onCancel={() => setEstimateModal(false)}
            footer={null}
            centered
          >
          <div className="confirm-info">
            <p>An amount of <b>${(estimates.amount_due)/100} USD</b> will be charged, when the Subscription becomes Active.</p>
            <p>Are you sure, you want to proceed?</p>
            <div>
              <Button type="danger" onClick={() => setEstimateModal(false)} className="btn">
                Cancel
              </Button>
              <Button type="primary" onClick={onReactivate} className="btn" loading={reactivateLoader}>
                Proceed
              </Button>
            </div>
          </div>
        </Modal>}
        </>
      );
}

export default ReactivateSubscription;