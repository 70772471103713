import * as actionTypes from "../Actions/ActionTypes";

const initState = {
  histories: [],
  loading: false,
  paginationData: null,
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        histories: action?.payload?.histories,
        paginationData: action?.payload?.paginationData,
      };
    case actionTypes.GET_ALL_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
