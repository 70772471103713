import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Layout, Menu, notification } from "antd";
import "./MainLayout.scss";
import { Outlet, useLocation, useNavigate } from "react-router";
import {
  DashboardOutlined,
  UserOutlined,
  CodeOutlined,
  HistoryOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  FileOutlined,
  GroupOutlined,
  FundOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import UserCreateModal from "../UserCreateModal/UserCreateModal";
import SubscriptionDetails from "../../Pages/SubscriptionDetails/SubcriptionDetails";
import axios from "../../Utils/axios";
import * as actionTypes from "../../Redux/Actions/ActionTypes";
import PageLoader from "../PageLoader";
import { getCurrentUserDetails } from "../../Services/user";
import { logout } from "../../Services/authentication";
import { getSubscriptionDetails } from "../../Services/subscription";
import { paymentStatuses } from "../../Routes/SubscriptionVerifiedRoute";
const { Header, Content, Sider } = Layout;

function MainLayout({ children }) {
  const [collapsed, setCollpsed] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [editUserData, setEdituser] = useState();
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isValidSubscription, setIsValidSubscription] = useState(false);

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const response = await getCurrentUserDetails(true);
      if (response?.user) {
        setUserData(response.user);
      } else {
        notification.error({
          message: response.data?.message,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  };

  const getSubscriptionInfo = async (subscriptionId) => {
    try {
      const response = await getSubscriptionDetails(subscriptionId);
      if (response?.data?.success) {
        if (response.data?.subscriptionDetails) {
          setSubscriptionData(response.data?.subscriptionDetails);
        }
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || error.message,
      });
    }
  };

  const checkIfIsEmailVerified = (user) => {
    if (user && user.is_email_verified) {
      setIsEmailVerified(true);
    }
  };

  const checkIfIsValidSubscription = (user, subscription) => {
    if (user && user.role === "admin") {
      setIsValidSubscription(true);
      return;
    }
    if (
      subscription &&
      paymentStatuses.includes(subscription?.status?.toLowerCase())
    ) {
      setIsValidSubscription(true);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userData && userData.payments && userData.payments.length > 0) {
      getSubscriptionInfo(userData.payments[0].subscription_id);
    }
  }, [userData]);

  useEffect(() => {
    checkIfIsEmailVerified(userData);
    checkIfIsValidSubscription(userData, subscriptionData);
  }, [userData, subscriptionData]);

  if (loading) {
    return <PageLoader />;
  }

  const handleLogout = async () => {
    await logout();
    window.location.reload();
  };
  const editUserProfile = async (userId, data = {}, onSuccess, onFail) => {
    try {
      const response = await axios.put(`/users/${userId}/profile`, data);
      if (response.data?.success) {
        dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } else {
        onFail && onFail(response.data?.message || "Failed to update user");
      }
    } catch (error) {
      onFail && onFail(error?.message || "Failed to update user");
    }
  };
  const handleUpdateUser = (values) => {
    const formData = new FormData();
    formData.append("firstName", values?.firstName);
    formData.append("lastName", values?.lastName);
    formData.append("organization", values?.organization || "");
    formData.append("email", values?.email);
    formData.append("avatar", values?.avatar?.file);

    dispatch(
      editUserProfile(
        editUserData?.id,
        formData,
        () => {
          notification.success({ message: "User updated successfully!!" });
          setCreateUserModalOpen(false);
          setEdituser();
        },
        (errorMessage) => {
          notification.error({
            message: errorMessage || "Failed to update user",
          });
        }
      )
    );
  };
  const handleEditProfile = () => {
    setEdituser(userData);
    setCreateUserModalOpen(true);
  };

  const menu = (
    <Menu>
      {isEmailVerified && isValidSubscription && (
        <>
          <Menu.Item
            key="profile"
            icon={<UserOutlined />}
            onClick={handleEditProfile}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            key="sub"
            onClick={() => setSubscriptionModalOpen(true)}
            icon={<BellOutlined />}
          >
            Subscription
          </Menu.Item>
        </>
      )}
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <LayoutWithHeader
      firstName={userData?.firstName?.[0]?.toUpperCase()}
      lastName={userData?.lastName?.[0]?.toUpperCase()}
      avatar={userData?.avatar}
      menu={menu}
    >
      <Layout>
        {isEmailVerified && isValidSubscription && (
          <Sider
            collapsible
            collapsed={collapsed}
            collapsedWidth={80}
            trigger={null}
            width={220}
            className="site-layout-background"
          >
            <Menu
              mode="inline"
              selectedKeys={[location?.pathname?.split("/")[1]]}
              style={{ borderRight: 0 }}
            >
              {userData?.role === "admin" && (
                <Menu.Item
                  onClick={() => navigate("/dashboard")}
                  key={"dashboard"}
                  icon={<DashboardOutlined />}
                >
                  Dashboard
                </Menu.Item>
              )}
              {userData?.role === "admin" && (
                <Menu.Item
                  onClick={() => navigate("/users")}
                  key={"users"}
                  icon={<UserOutlined />}
                >
                  Users
                </Menu.Item>
              )}
              {userData?.role === "admin" && (
                <Menu.Item
                  onClick={() => navigate("/prompts")}
                  key={"prompts"}
                  icon={<CodeOutlined />}
                >
                  Prompts
                </Menu.Item>
              )}
              {userData?.role === "user" && (
                <Menu.Item
                  onClick={() => navigate("/companies")}
                  key={"companies"}
                  icon={<GroupOutlined />}
                >
                  Companies
                </Menu.Item>
              )}
              {userData?.role === "user" && (
                <Menu.Item
                  onClick={() => navigate("/success-plans")}
                  key={"success-plans"}
                  icon={<FundOutlined />}
                >
                  Success Plans
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() => navigate("/history")}
                key={"history"}
                icon={<HistoryOutlined />}
              >
                History
              </Menu.Item>
              {/* {userData?.role === "admin" && (
              <Menu.Item
                onClick={() => navigate("/tags")}
                key={"tags"}
                icon={<TagOutlined />}
              >
                Tags
              </Menu.Item>
            )} */}
              {userData?.role === "admin" && (
                <Menu.Item
                  onClick={() => navigate("/documents")}
                  key={"documents"}
                  icon={<FileOutlined />}
                >
                  Documents
                </Menu.Item>
              )}
              {userData?.role === "admin" && (
                <Menu.Item
                  onClick={() => navigate("/settings")}
                  key={"settings"}
                  icon={<SettingOutlined />}
                >
                  Settings
                </Menu.Item>
              )}
            </Menu>
            <div className="sidebar-bottom">
              {!collapsed ? (
                <MenuFoldOutlined onClick={() => setCollpsed(true)} />
              ) : (
                <MenuUnfoldOutlined onClick={() => setCollpsed(false)} />
              )}
            </div>
          </Sider>
        )}
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      {isEmailVerified && isValidSubscription && (
        <UserCreateModal
          isOpen={createUserModalOpen}
          handleOpenChange={(open) => setCreateUserModalOpen(open)}
          intialValues={editUserData}
          handleUpdateUser={handleUpdateUser}
          isEditMode={true}
          openedFromProfile={true}
        />
      )}
      {subscriptionModalOpen && (
        <SubscriptionDetails
          modalVisibility={subscriptionModalOpen}
          handleOpenChange={() => setSubscriptionModalOpen(false)}
        />
      )}
    </LayoutWithHeader>
  );
}

export const LayoutWithHeader = ({
  firstName,
  lastName,
  avatar,
  menu,
  children,
}) => {
  return (
    <Layout className="main-layout">
      <Header className="header">
        <div className="header-left">
          <div className="logo">
            <img src={"/logo.png"} />
          </div>
          <span className="logo-title">Sales Bod</span>
        </div>
        <Dropdown placement="bottomRight" overlay={menu} trigger={["click"]}>
          <Avatar size={"large"} src={avatar} style={{ cursor: "pointer" }}>
            {firstName}
            {lastName}
          </Avatar>
        </Dropdown>
      </Header>
      {children}
    </Layout>
  );
};

export default MainLayout;
