import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import Companies from "../Pages/Companies/Companies";
import CompanyDetails from "../Pages/CompanyDetails/CompanyDetails";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Documents from "../Pages/Documents/Documents";
import ForgotPasswordPage from "../Pages/ForgotPassword/ForgotPassword";
import History from "../Pages/History/History";
import HistoryDetails from "../Pages/HistoryDetails/HistoryDetails";
import LoginPage from "../Pages/Login/Login";
import PromptCreate from "../Pages/PromptCreate/PromptCreate";
import PromptDetails from "../Pages/PromptDetails/PromptDetails";
import Prompts from "../Pages/Prompts/Prompts";
import ResetPasswordPage from "../Pages/ResetPassword/ResetPassword";
import SetPasswordPage from "../Pages/SetPassword/SetPassword";
import SettingCreate from "../Pages/SettingCreate/SettingCreate";
import SettingDetails from "../Pages/SettingDetails/SettingDetails";
import Settings from "../Pages/Settings/Settings";
import SignupPage from "../Pages/Signup/Signup";
import SuccessPlanDetails from "../Pages/SuccessPlanDetails/SuccessPlanDetails";
import SuccessPlans from "../Pages/SuccessPlans/SuccessPlans";
import Tags from "../Pages/Tags/Tags";
import Users from "../Pages/Users/Users";
import AdminProtectedRoute from "./AdminProtectedRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import UserProtectedRoute from "./UserProtectedRoute";
import VerifyEmail from "../Pages/VerifyEmail";
import RefundDetails from "../Pages/RefundDetails/RefundDetails";

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<Navigate replace to={"/dashboard"} />} />
        <Route
          path="/dashboard"
          element={
            <AdminProtectedRoute>
              <Dashboard />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <AdminProtectedRoute>
              <Users />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/prompts"
          element={
            <AdminProtectedRoute>
              <Prompts />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/prompts/create"
          element={
            <AdminProtectedRoute>
              <PromptCreate />
            </AdminProtectedRoute>
          }
        />
        <Route path="/prompts/:promptId" element={<PromptDetails />} />
        <Route
          path="/settings"
          element={
            <AdminProtectedRoute>
              <Settings />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/settings/create"
          element={
            <AdminProtectedRoute>
              <SettingCreate />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/settings/:settingId"
          element={
            <AdminProtectedRoute>
              <SettingDetails />
            </AdminProtectedRoute>
          }
        />
        <Route path="/history" element={<History />} />
        <Route path="/history/:history_name" element={<HistoryDetails />} />
        <Route
          path="/tags"
          element={
            <AdminProtectedRoute>
              <Tags />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/documents"
          element={
            <AdminProtectedRoute>
              <Documents />
            </AdminProtectedRoute>
          }
        />
        <Route path="/companies" element={<UserProtectedRoute><Companies /></UserProtectedRoute>} />
        <Route path="/companies/:companyId" element={<UserProtectedRoute><CompanyDetails /></UserProtectedRoute>} />
        <Route path="/success-plans" element={<UserProtectedRoute><SuccessPlans /></UserProtectedRoute>} />
        <Route
          path="/success-plans/:successPlanId/draft"
          element={<UserProtectedRoute><SuccessPlanDetails /></UserProtectedRoute>}
        />
        <Route
          path="/success-plans/:successPlanId"
          element={<UserProtectedRoute><SuccessPlanDetails /></UserProtectedRoute>}
        />
        <Route
          path="/refund"
          element={<AdminProtectedRoute><RefundDetails /></AdminProtectedRoute>}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/auth/verify-email" element={<VerifyEmail />} />
      <Route path="/" element={<PublicRoute />}>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/signup" element={<SignupPage />} />
        <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
        <Route path="/auth/set-password" element={<SetPasswordPage />} />
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
