import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllDocuments = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_DOCUMENTS_INIT,
    });
    instance
      .get(`/documents`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_DOCUMENTS_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_DOCUMENTS_FAIL,
            payload: res.data?.message || "Failed to get documents",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_DOCUMENTS_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get documents",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const createNewDocument = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NEW_DOCUMENT_INIT,
    });
    instance
      .post(`/documents`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.CREATE_NEW_DOCUMENT_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.CREATE_NEW_DOCUMENT_FAIL,
            payload: res.data?.message || "Failed to create new document",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.CREATE_NEW_DOCUMENT_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed create new document",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const getDocumentById = (documentId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_DOCUMENT_BY_ID_INIT,
    });
    instance
      .get(`/documents/${documentId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_DOCUMENT_BY_ID_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_DOCUMENT_BY_ID_FAIL,
            payload: res.data?.message || "Failed to get document",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_DOCUMENT_BY_ID_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed to get document",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const editDocument = (documentId, data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_DOCUMENT_INIT,
    });
    instance
      .put(`/documents/${documentId}`, data)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.EDIT_DOCUMENT_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.EDIT_DOCUMENT_FAIL,
            payload: res.data?.message || "Failed to update document",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.EDIT_DOCUMENT_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed update document",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};

export const deleteDocument = (documentId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_DOCUMENT_INIT,
    });
    instance
      .delete(`/documents/${documentId}`)
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.DELETE_DOCUMENT_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.DELETE_DOCUMENT_FAIL,
            payload: res.data?.message || "Failed to delete new document",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.DELETE_DOCUMENT_FAIL,
          payload:
            e?.response?.data?.message ||
            e?.message ||
            "Failed delete new document",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
