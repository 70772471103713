import axios from "../Utils/axios";

const getMeProfile = async () => axios.get("/users/me");

export const setCurrentUserDetails = async (userDetails) =>
  localStorage.setItem("currentUser", JSON.stringify(userDetails));

const getUserInfoFromApi = async () => {
  let userInfo = null;
  try {
    const userDetails = await getMeProfile();
    if (userDetails.data.success) {
      await setCurrentUserDetails(userDetails.data.user);
      return userDetails.data;
    }
  } catch (error) {
    console.log(error);
  }
  return userInfo;
};

export const getCurrentUserDetails = async (fetchLatest) => {
  let userInfo = null;

  try {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || null);
    if (!currentUser || fetchLatest) {
      userInfo = await getUserInfoFromApi();
    } else {
      userInfo = currentUser;
    }
  } catch (error) {
    userInfo = await getUserInfoFromApi();
  }

  return userInfo;
};
