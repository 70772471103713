import instance from "../../Utils/axios";
import * as actionTypes from "./ActionTypes";

export const getAllHistory = (data = {}, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ALL_HISTORY_INIT,
    });
    instance
      .get(`/history`, {
        params: data,
      })
      .then((res) => {
        if (res.data?.success) {
          dispatch({
            type: actionTypes.GET_ALL_HISTORY_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res.data);
        } else {
          dispatch({
            type: actionTypes.GET_ALL_HISTORY_FAIL,
            payload: res.data?.message || "Failed to get histories",
          });
          onFail && onFail(res.data?.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_ALL_HISTORY_FAIL,
          payload:
            e?.response?.data?.message || e?.message || "Failed get histories",
        });
        onFail && onFail(e?.response?.data?.message || e?.message);
      });
  };
};
